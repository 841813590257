import React from 'react';
import Checkbox from 'components/form-input/Checkbox';
import styles from './dropdown.module.scss';
import { ImInfo } from 'react-icons/im';

function Grouped({
  options = [],
  groupKey,
  selectedOptions = {},
  onGroupCheckboxCheck = () => {},
  showTooltipInfo = false,
}) {
  const renderGroupedList = (list, index) => {
    return list[groupKey]?.map((option) => {
      const selectedOption = selectedOptions?.find(
        (selectedOption) =>
          selectedOption.group === list.name && selectedOption.label === option.label,
      );
      const isChecked = selectedOption ? selectedOption.isChecked : false;

      return (
        <React.Fragment key={index + '-' + option.label}>
          <li
            className={`txt size-14 font-inter w-500 d-flex align-items-center 
          ${styles.dropdownOption} 
          ${option.disabled ? 'disabled-filter' : ''}`}
          >
            <Checkbox
              buttonName={option.label}
              onChange={(e) => onGroupCheckboxCheck(e, option, list.name)}
              name={option.name}
              value={option.value}
              checked={isChecked}
              id={`checkbox-mobile-filter ${option}-${option.name}`}
              disabled={option.disabled ? true : false}
            />
            {showTooltipInfo ? (
              <div className="ml-2 clickable request-section-tooltip h-100 d-flex align-items-center">
                <ImInfo />
                <span
                  className={`request-section-tooltiptext datasource-info-top-tooltip txt size-13 ${
                    option.name.length <= 15
                      ? 'datasource-xsm-tooltipText'
                      : option.name.length <= 25
                      ? 'datasource-sm-tooltipText'
                      : 'datasource-responsive-tooltip'
                  }`}
                >
                  {option.description}
                </span>
              </div>
            ) : null}
          </li>
        </React.Fragment>
      );
    });
  };

  return options?.map((option, index) => (
    <div className={styles.optionGroup} key={index + '-' + option.name}>
      <div className={styles.groupedLabel}>{option.name}</div>
      {renderGroupedList(option, index)}
      {index !== options?.length - 1 && <div className={styles.groupseparator}></div>}
    </div>
  ));
}

export default Grouped;
