import React from 'react';
import DropdownMenu from 'components/dropdowns/DropdownMenu';
import { TextInput } from 'components/form-input';
import { Strings } from 'constants/Strings';
import { useDispatch, useSelector } from 'react-redux';
import { setPhenotypeData } from 'redux/modules/orderDetails/actions';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';

const outcomePeriodOptions = [
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Months',
    value: 'months',
  },
  {
    label: 'Years',
    value: 'years',
  },
];

function OutcomePeriod({ entity, entityRowIndex, phenotype }) {
  const dispatch = useDispatch();
  const {
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
  } = useOrdersPagedata();

  const {
    phenotypeResponse: { phenotypes },
  } = useSelector(({ orderDetails }) => orderDetails);
  const {
    outcome_min_value = 3,
    outcome_max_value = 1,
    outcome_min_type = 'days',
    outcome_max_type = 'years',
  } = entity;

  const selectedValue = {
    min: outcomePeriodOptions.find((v) => v.value === outcome_min_type),
    max: outcomePeriodOptions.find((v) => v.value === outcome_max_type),
  };

  const handleSelect = (type, option) => {
    const phenotypeData = structuredClone(phenotypes);
    const outcomePhenotype = phenotypeData?.[phenotype]?.entities?.[entityRowIndex];
    if (outcomePhenotype) {
      const outcomeValues = {
        ...outcomePhenotype,
        [type]: option?.value,
      };

      phenotypeData[phenotype].entities[entityRowIndex] = outcomeValues;
      dispatch(setPhenotypeData(phenotypeData));
    }
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  };

  function scrollToBottom() {
    setTimeout(() => {
      const element = document.getElementById('Phenotype-table');
      if (element) {
        element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  }
  function handleChange(e) {
    const { value, name } = e.target;

    if (value === 0) {
      if (chatRWD.isGeneralStudyParamVisible) {
        dispatchOrderPageAction({
          type: 'order/updateChatRWDField',
          payload: {
            field: 'isGeneralStudyParamVisible',
            value: false,
          },
        });
      }
    }
    if (value < 0) {
      e.preventDefault();
      return;
    }
    const phenotypeData = structuredClone(phenotypes);
    const outcomePhenotype = phenotypeData?.[phenotype]?.entities?.[entityRowIndex];
    if (outcomePhenotype) {
      const outcomeValues = {
        ...outcomePhenotype,
        [name]: Number(value),
      };
      phenotypeData[phenotype].entities[entityRowIndex] = outcomeValues;
      dispatch(setPhenotypeData(phenotypeData));
    }
  }

  return (
    <div className="d-flex flex-column gap-15" id="custom-textinput-container">
      <div className="d-flex flex-column gap-10">
        <div className="txt size-15 w-600 lh-28 form-input-header">
          Outcome time period
        </div>
        <div className="custom-textinput-container input-wrapper">
          <div className="d-xl-flex gap-50">
            <div>
              <label
                className="mb-1 txt size-14 w-500 lh-20 font-inter primary-1"
                htmlFor="start"
              >
                Start
              </label>
              <div className="d-lg-flex gap-20">
                <TextInput
                  cssClass="input-layout-1 input-1 mr-0 txt font-inter primary-1 size-14 w-500 mb-1"
                  type="number"
                  value={outcome_min_value}
                  onChange={handleChange}
                  name="outcome_min_value"
                />

                <div className="input-conatainer">
                  <div className="input-2">
                    <DropdownMenu
                      options={outcomePeriodOptions}
                      selectedOptions={selectedValue?.min}
                      onClickonOption={(option) =>
                        handleSelect('outcome_min_type', option)
                      }
                      placeholder="Select"
                      height="35px"
                      optionsPadding="small"
                      onDropdownControlClick={scrollToBottom}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label
                className="mb-1 txt size-14 w-500 lh-20 font-inter primary-1"
                htmlFor="start"
              >
                End
              </label>
              <div className="d-lg-flex gap-20">
                <TextInput
                  cssClass="input-layout-1 input-1 mr-0 txt font-inter primary-1 size-14 w-500 mb-1"
                  type="number"
                  value={outcome_max_value}
                  onChange={handleChange}
                  name="outcome_max_value"
                />
                <div className="input-conatainer">
                  <div className="input-2">
                    <DropdownMenu
                      options={outcomePeriodOptions}
                      selectedOptions={selectedValue?.max}
                      onClickonOption={(option) =>
                        handleSelect('outcome_max_type', option)
                      }
                      placeholder="Select"
                      height="35px"
                      optionsPadding="small"
                      onDropdownControlClick={scrollToBottom}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {outcome_max_value <= 0 && (
            <div className="color-error error-text">{Strings.outcomePeriodError}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OutcomePeriod;
