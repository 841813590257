import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AiFillCheckCircle } from 'react-icons/ai';
import { ButtonCustom } from 'components/form-input/Button';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import PicotDataDisplay from './PicotDataDisplay';
import Frame from './Frame';
import styles from './chatrwd.module.scss';
import StudyForm from './StudyForm';
import { useOrdersPagedata } from '../OrderPageContext';
import StudyDetailsSummary from './StudyDetailsSummary';

const defaultButtons = {
  TRY_AGAIN: 'Try Again',
  START_OVER: 'Start Over',
};

function StudyDetails({
  studyFormatObject = {},
  customButtons = {},
  dispatch,
  onOptionClick,
  multiSelect = false,
  selectedSuggestionsOptions = undefined,
  index = null,
}) {
  const { buttons, suggestions, updated_pico, isEdit } = studyFormatObject;
  const [options, setOptions] = useState([]);
  const { PICOGuid } = useSelector(({ orderDetails }) => orderDetails);
  const { showButtonIcons } = useOrdersPagedata();

  function handleButtonClick(option, key) {
    if (
      key === aiButtonsEnum.TRY_AGAIN ||
      key === aiButtonsEnum.START_OVER ||
      key === aiButtonsEnum.MANUALLY_EDIT_QUESTION ||
      key === aiButtonsEnum.SUGGESTIONS_MANUALLY_EDIT ||
      key === aiButtonsEnum.REGENERATE_PICOT
    ) {
      onOptionClick({ options, key, index });
      return;
    }

    if (multiSelect) {
      switch (key) {
        case aiButtonsEnum.INCORPORATE_CHANGES:
          const selectedSuggestionObj = { ...options };
          dispatch({
            type: 'order/setSuggestionsOptions',
            payload: selectedSuggestionObj,
          });
          onOptionClick({ options: selectedSuggestionObj, key, index });
          dispatch({
            type: 'order/setUserAction',
            payload: {
              userAction: key,
              options: { options: options, PICOGuid },
            },
          });
          setOptions([]);
          break;
        case aiButtonsEnum.KEEP_ORIGINAL:
        case aiButtonsEnum.REGENERATE_SUGGESTIONS:
          dispatch({ type: 'order/setSuggestionsOptions', payload: { option } });
          onOptionClick({ option, key, index });

          dispatch({
            type: 'order/setUserAction',
            payload: { userAction: key, options: undefined },
          });
          break;
        default:
          if (!options.includes(option)) {
            setOptions((op) => [...op, option]);
          } else {
            const newOptions = options.filter((op) => op !== option);
            setOptions(newOptions);
          }
          break;
      }
    } else {
      if (!(key === aiButtonsEnum.MANUALLY_EDIT || key === aiButtonsEnum.START_OVER)) {
        dispatch({ type: 'order/setSuggestionsOptions', payload: { option } });

        dispatch({
          type: 'order/setUserAction',
          payload: {
            userAction: key,
            options: { PICOGuid },
          },
        });
      }
      onOptionClick({ option, key, index });
    }
  }

  function handleStudyFormActions(key) {
    onOptionClick({ key, index: index });
  }

  if (studyFormatObject && 'suggestions' in studyFormatObject) {
    return (
      <>
        <Frame dataTestid={`frame-${index}`}>
          <div className="d-md-flex gap-25">
            <Frame.Aside></Frame.Aside>
            <StudyDetailsSummary studyFormatObject={studyFormatObject}>
              {suggestions &&
                Array.isArray(suggestions) &&
                suggestions.length > 0 &&
                suggestions.map((suggestion, index) => (
                  <div className="mb-1 detail-item" key={suggestion.id}>
                    <strong className="txt size-16 gray-20 lh-28 text-bold">
                      {index + 1}. {suggestion.name} ({suggestion.field}):{' '}
                    </strong>
                    <span className="txt size-16 gray-20 lh-28">
                      {suggestion.description}
                    </span>
                  </div>
                ))}
            </StudyDetailsSummary>
          </div>
        </Frame>

        <Frame.FrameFooter dataTestid={`frame-footer-${index}`}>
          {!selectedSuggestionsOptions ? (
            <div className="green-border w-100">
              <div className="flex-wrap flex-justify-end d-flex gap-7">
                {buttons && Object.keys(buttons).length > 0
                  ? Object.entries(buttons).map(([key, value], index) => (
                      <React.Fragment key={value}>
                        {suggestions &&
                        Array.isArray(suggestions) &&
                        suggestions.length > 0 ? (
                          <ButtonCustom
                            dataTestid="suggestion-option"
                            cssClass={`blue-btn mb-md-1 ${styles.aiButton} ${
                              options.includes(`${index + 1}. ${value}`) &&
                              styles.activeBg
                            }`}
                            buttonAction={`${value} button clicked`}
                            id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                            onClick={() =>
                              handleButtonClick(`${index + 1}. ${value}`, key)
                            }
                          >
                            <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                              <span>{`${index + 1}. ${value}`}</span>
                            </div>
                          </ButtonCustom>
                        ) : (
                          <ButtonCustom
                            cssClass={`blue-btn mb-3 mb-md-1 ${styles.aiButton} ${
                              options.includes(`${index + 1}. ${value}`) && 'active-bg'
                            }`}
                            buttonAction={`${value} button clicked`}
                            id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                            onClick={() => handleButtonClick(value, key)}
                          >
                            <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                              <span>{value}</span>
                            </div>
                          </ButtonCustom>
                        )}
                      </React.Fragment>
                    ))
                  : Object.entries(defaultButtons).map(([key, value]) => (
                      <React.Fragment key={value}>
                        <ButtonCustom
                          cssClass="blue-btn mb-3 mx-md-1 mx-0"
                          buttonAction={`${value} button clicked`}
                          id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                          onClick={() => handleButtonClick(value, key)}
                        >
                          <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                            {showButtonIcons(key)}
                            <span>{value}</span>
                          </div>
                        </ButtonCustom>
                      </React.Fragment>
                    ))}
              </div>
              <div className="flex-wrap flex-justify-end d-flex gap-7 mt-2">
                {suggestions &&
                  Array.isArray(suggestions) &&
                  suggestions.length > 0 &&
                  customButtons &&
                  buttons &&
                  Object.keys(buttons).length > 0 &&
                  Object.entries(customButtons).map(([key, value], index) => (
                    <React.Fragment key={value}>
                      <ButtonCustom
                        cssClass={`blue-btn ${styles.aiButton} ${
                          key === 'INCORPORATE_CHANGES' && options.length === 0
                            ? 'btn-disabled'
                            : ''
                        }`}
                        buttonAction={`${value} button clicked`}
                        id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                        onClick={() => handleButtonClick(value, key)}
                        disabled={key === 'INCORPORATE_CHANGES' && options.length === 0}
                      >
                        <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                          <span>{value}</span>
                        </div>
                      </ButtonCustom>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div className="flex-wrap flex-justify-end green-border px-md-2 d-flex gap-7">
              {selectedSuggestionsOptions &&
                Object.entries(selectedSuggestionsOptions).map(([key, value], index) => (
                  <React.Fragment key={value}>
                    <ButtonCustom
                      cssClass={`${styles.activeBg} ${styles.aiButton} blue-btn`}
                      buttonAction={`${value} button clicked`}
                      id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                      onClick={() => {
                        return;
                      }}
                    >
                      <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                        {(value === 'Keep Original' ||
                          value === 'Regenerate Suggestions') && <AiFillCheckCircle />}
                        <span>{value}</span>
                      </div>
                    </ButtonCustom>
                  </React.Fragment>
                ))}
            </div>
          )}
        </Frame.FrameFooter>
      </>
    );
  } else {
    return (
      <>
        {isEdit ? (
          <StudyForm
            studyFormat={studyFormatObject?.study_format}
            data={studyFormatObject}
            onCancel={() =>
              handleStudyFormActions(aiButtonsEnum.CANCEL_CURRENT_FORMAT_EDIT)
            }
            onFormSubmit={() =>
              handleStudyFormActions(aiButtonsEnum.SAVE_CURRENT_FORMAT_EDIT)
            }
          />
        ) : (
          <>
            <Frame dataTestid={`frame-${index}`}>
              <div className="d-md-flex gap-25">
                <Frame.Aside></Frame.Aside>
                <StudyDetailsSummary studyFormatObject={studyFormatObject}>
                  {studyFormatObject?.study_format &&
                    Object.entries(studyFormatObject?.study_format).map(
                      ([key, value]) => (
                        <React.Fragment key={key}>
                          <PicotDataDisplay
                            updated_pico={updated_pico}
                            data={{ key, value }}
                          />
                        </React.Fragment>
                      ),
                    )}
                </StudyDetailsSummary>
              </div>
            </Frame>
            <Frame.FrameFooter dataTestid={`frame-footer-${index}`}>
              {!isEdit && !selectedSuggestionsOptions ? (
                <div className="flex-wrap flex-justify-end green-border d-flex gap-10 w-100">
                  {buttons && Object.keys(buttons).length > 0
                    ? Object.entries(buttons).map(
                        ([key, value]) =>
                          ((index !== 0 && key !== aiButtonsEnum.REGENERATE_PICOT) ||
                            index === 0) && (
                            <React.Fragment key={value}>
                              {key === aiButtonsEnum.PICOT_ACCURATE && (
                                <div className="flex-basis-100"> </div>
                              )}
                              <ButtonCustom
                                cssClass={`blue-btn ${styles.aiButton}`}
                                buttonAction={`${value} button clicked`}
                                id={`btn-${value
                                  .toLowerCase()
                                  .replace(/\s+/g, '-')}-button`}
                                onClick={() => handleButtonClick(value, key)}
                              >
                                <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                                  {showButtonIcons(key)}
                                  <span>{value}</span>
                                </div>
                              </ButtonCustom>
                            </React.Fragment>
                          ),
                      )
                    : Object.entries(defaultButtons).map(([key, value]) => (
                        <React.Fragment key={value}>
                          <ButtonCustom
                            cssClass={`blue-btn ${styles.aiButton}`}
                            buttonAction={`${value} button clicked`}
                            id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                            onClick={() => handleButtonClick(value, key)}
                          >
                            <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                              {showButtonIcons(key)}
                              <span>{value}</span>
                            </div>
                          </ButtonCustom>
                        </React.Fragment>
                      ))}
                </div>
              ) : (
                !isEdit && (
                  <div className="flex-wrap flex-justify-end green-border">
                    {selectedSuggestionsOptions &&
                      Object.entries(selectedSuggestionsOptions).map(
                        ([key, value], index) => (
                          <React.Fragment key={value}>
                            <ButtonCustom
                              cssClass={`${styles.activeBg} blue-btn ${styles.aiButton} `}
                              buttonAction={`${value} button clicked`}
                              id={`btn-${value
                                .toLowerCase()
                                .replace(/\s+/g, '-')}-button`}
                              onClick={() => {
                                return;
                              }}
                            >
                              <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                                <AiFillCheckCircle />
                                <span>{value}</span>
                              </div>
                            </ButtonCustom>
                          </React.Fragment>
                        ),
                      )}
                  </div>
                )
              )}
            </Frame.FrameFooter>
          </>
        )}
      </>
    );
  }
}

export default StudyDetails;
