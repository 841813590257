import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DropdownMenu from 'components/dropdowns/DropdownMenu';
import { useOrdersPagedata } from '../OrderPageContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Strings } from 'constants/Strings';

function DatasourceOptions() {
  const location = useLocation();
  const { pathname } = location;
  const orderTypePath = pathname?.split('/')[2];
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const { datasetList } = useSelector(({ userSubscription }) => userSubscription);
  const { ordersPageData, dispatchOrderPageAction, isReorder } = useOrdersPagedata();
  const { errorsObject, selectedDataSourceOption } = ordersPageData;
  const { data_source_object } = singleOrderData || {};

  useEffect(() => {
    if (isReorder && data_source_object) {
      dispatchOrderPageAction({
        type: 'order/setselectedDataSourceOption',
        payload: [
          {
            ...data_source_object,
            group: data_source_object?.hierarchy,
            label: data_source_object?.name || data_source_object?.scope,
            value: data_source_object?.name || data_source_object?.scope,
            isChecked: true,
          },
        ],
      });
    }
  }, [isReorder, data_source_object]);

  const addLabelAndValueToDataSources = (data) => {
    return data?.map((entry) => {
      const updatedDataSources = entry.data_sources.map((dataSource) => ({
        ...dataSource,
        label: dataSource.name,
        value: dataSource.name,
      }));

      return {
        ...entry,
        data_sources: updatedDataSources,
      };
    });
  };

  const handleDataSourceOptionChange = (e, option, group) => {
    const { name, checked } = e.target;
    const groupExists = selectedDataSourceOption?.some(
      (opt) => opt.group === group && opt.label === name,
    );

    let updatedOptions;
    if (groupExists) {
      updatedOptions = selectedDataSourceOption?.map((opt) =>
        opt.group === group && opt.label === name ? { ...opt, isChecked: checked } : opt,
      );
    } else {
      updatedOptions = [
        ...selectedDataSourceOption,
        {
          group,
          label: name,
          isChecked: checked,
          ...option,
        },
      ];
    }
    const allUnchecked = updatedOptions.every((opt) => !opt.isChecked);
    if (allUnchecked) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: 'dataSourceSelectionError',
          error: Strings.dataSourceSelectionError,
        },
      });
    } else {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: 'dataSourceSelectionError',
          error: '',
        },
      });
    }

    dispatchOrderPageAction({
      type: 'order/setselectedDataSourceOption',
      payload: updatedOptions,
    });
  };

  return (
    <div
      className={`data-source-container position-relative ${
        singleOrderData?.guid &&
        singleOrderData?.status === 'Pending' &&
        orderTypePath === 'edit'
          ? // orderFormData?.isEditOrder
            'disabled-data'
          : ''
      }`}
    >
      <div className="advanced-setting-title mb-2">Data Sources</div>

      <div className="mb-2 sub-heading-text">
        To learn more about our data sources,{' '}
        <a className="" href={'mailto:support@atroposhealth.com'} id={'email-click-here'}>
          click here
        </a>
      </div>

      <DropdownMenu
        type="group"
        groupKey="data_sources"
        options={addLabelAndValueToDataSources(datasetList)}
        onGroupCheckboxCheck={handleDataSourceOptionChange}
        selectedOptions={selectedDataSourceOption}
        placeholder="Select Answer"
        disabled={
          singleOrderData?.guid &&
          singleOrderData?.status === 'Pending' &&
          orderTypePath === 'edit'
        }
        isNoValidate={errorsObject?.dataSourceSelectionError ? true : false}
        noScroll={true}
        showTooltipInfo={true}
      />

      {errorsObject.dataSourceSelectionError && (
        <div className="color-error error-text">
          {errorsObject.dataSourceSelectionError}
        </div>
      )}
    </div>
  );
}

export default DatasourceOptions;
