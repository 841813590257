import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styles from './phenotype.module.scss';
import PhenotypeTable from './PhenotypeTable';
import { labels } from 'constants/labels';

function Phenotype({ data }) {
  function hasUnableToReturnPhenotype(studyDetail) {
    return studyDetail?.entities?.some((entity) =>
      entity.phenotypes.some((phenotype) =>
        phenotype.code_set.some(
          (codeSet) =>
            codeSet.concept_description === labels.unableToReturnPhenotypeDescription,
        ),
      ),
    );
  }

  return (
    <Tabs>
      <TabList className={styles.customTabList}>
        {data &&
          Object.keys(data).map((key) => {
            const item = data[key];
            const hasEntities = item.entities?.length > 0;
            // if (!hasEntities) return null;

            const isError = hasUnableToReturnPhenotype(item);
            const classNames = `text-capitalize ${isError ? 'color-error' : ''}`;

            return (
              <Tab key={`tab-${key}`}>
                <span className={classNames}>{key}</span>
              </Tab>
            );
          })}
      </TabList>

      {data &&
        Object.keys(data).map(
          (key, index) =>
            Object.keys(data[key]).length > 0 && (
              <TabPanel key={`tab-${key}-body-${index}`}>
                <PhenotypeTable data={data} phenotypes={key} />
              </TabPanel>
            ),
        )}
    </Tabs>
  );
}

export default Phenotype;
