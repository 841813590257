import React from 'react';
import StudyForm from './StudyForm';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { useOrdersPagedata } from '../OrderPageContext';

const studyFormat = {
  population: '',
  intervention: '',
  control: '',
  outcome: '',
};

function ManualEnterStudyFormat() {
  const { dispatchOrderPageAction, handleButtonAction } = useOrdersPagedata();

  function handleFormSubmit() {
    handleButtonAction({ key: aiButtonsEnum.MANUALLY_SAVE_PICO });
  }

  function handleCancel() {
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'isManualInputMode',
        value: false,
      },
    });
  }

  return (
    <StudyForm
      studyFormat={studyFormat}
      onFormSubmit={handleFormSubmit}
      onCancel={handleCancel}
    />
  );
}

export default ManualEnterStudyFormat;
