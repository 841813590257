import React from 'react';
import MultiRangeSlider from 'multi-range-slider-react';

const RangeSlider = ({ min, max, minValue, maxValue, handleInputChange, step, name }) => {
  return (
    <div className="custom-rage-slider">
      <MultiRangeSlider
        min={min}
        max={max}
        step={step}
        minValue={minValue}
        maxValue={maxValue}
        onInput={(e) => {
          handleInputChange(e, name);
        }}
        ruler={false}
        style={{ border: 'none', boxShadow: 'none' }}
        barInnerColor={'#0D3F3C'}
        barRightColor={'#c1c1c1'}
        barLeftColor={'#c1c1c1'}
        thumbLeftColor={'#ffffff'}
        thumbRightColor={'#ffffff'}
        label={false}
      />
    </div>
  );
};

export default RangeSlider;
