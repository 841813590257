import React from 'react';
import styles from './dropdown.module.scss';

function Select({
  options = [],
  onOptionClick = () => {},
  selectedOptions = {},
  optionsPadding = 'medium',
}) {
  const getPaddingClass = (padding) => {
    switch (padding) {
      case 'small':
        return styles.paddingSmall;
      default:
        return '';
    }
  };
  return options?.map((option, index) => (
    <li
      onClick={() => onOptionClick(option)}
      key={index + '-' + option.name}
      className={`${styles.dropdownOption} 
      ${getPaddingClass(optionsPadding)} 
      txt size-14 font-inter w-500
      ${option.disabled ? 'disabled-filter' : ''} 
      ${
        selectedOptions?.label === option?.label ||
        selectedOptions?.checkedOption === option?.label
          ? styles.selected
          : ''
      }`}
    >
      <div className="txt size-14 w-500 font-inter d-flex justify-content-between align-items-center">
        {option?.label}
        {(selectedOptions?.label === option?.label ||
          selectedOptions?.checkedOption === option?.label) && (
          <div className="img size-16 check-mark"></div>
        )}
      </div>
    </li>
  ));
}

export default Select;
