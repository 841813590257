import React, { useState, useEffect, useRef } from 'react';
import TextArea from 'components/form-input/TextArea';
import { ButtonCustom, ButtonLink } from 'components/form-input/Button';
import OrderPrognostogramHeader from 'components/OrderPrognostogramHeader';
import { useHistory, useLocation } from 'react-router-dom';
import {
  orderPrognostogram,
  resetData,
  clearOrderFormData,
  setOrderFormData,
  modifyPrognostogramOrder,
  resetOrderIndexData,
  uploadOrderDocument,
  resetUploadDocumentData,
  resetDownloadLink,
} from 'redux/modules/orderDetails/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import AuthLoader from 'components/AuthLoader';
import { INPUT_SIZE, PICOT_QUESTION_LINK } from 'constants/Constants';
import orderHeadereEnum from '../constants/enum/orderHeadereEnum';
import { ImCheckmark, ImInfo } from 'react-icons/im';
import { ButtonSecondary } from 'components/form-input/Button';
import Switch from 'react-switch';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { requestGetUserProfile } from 'redux/modules/userProfile/actions';
import { HiOutlineChevronRight, HiOutlineChevronDown } from 'react-icons/hi';
import Checkbox from 'components/form-input/Checkbox';
import { getRawToken } from '../utils/validations';
import { useMixpanel } from 'react-mixpanel-browser';
import orderFormErrorStr from 'constants/errorStrings';
import Cookies from 'js-cookie';
import CustomModal from '../components/CustomModal';
import { VscChromeClose } from 'react-icons/vsc';
import { MdError } from 'react-icons/md';
import { Strings } from '../constants/Strings';
import FileDownloadUtil from '../utils/FileDownloadUtil';
import resourceEnum from '../constants/enum/resourceEnum';
import { getSubscriptionDataset } from 'redux/modules/userSubscription/actions';

const ClinicalQuestionForm = () => {
  const ref = useRef(null);
  const dropDownRef = useRef(null);
  const dataSourceDivRef = useRef(null);
  const refConfidential = useRef(null);
  let history = useHistory();
  const mixpanel = useMixpanel();
  const location = useLocation();
  const dispatch = useDispatch();
  const { getDownloadUrl } = FileDownloadUtil();
  const { isAuthenticated, getIdTokenClaims, loginWithRedirect } = useAuth0();
  const [questionGuid, setQuestionGuid] = useState('');
  const [questionBackgroundColor, setBackgroundColor] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipConfidential, setShowTooltipConfidential] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [isQuestionTypeDropDownFlag, setQuestionTypeDropDownFlag] = useState(false);
  const [questionTypeValue, setQuestionTypeValue] = useState('');
  const [questionName, setQuestionName] = useState('');
  const [formErrors, setFormErrors] = useState('');
  const {
    isOrderLoading,
    orderPrognostogramError,
    questionData,
    orderFormData,
    orderScopeData,
    uploadedDocumentInfo,
    isUploadingDocument,
    getDocumentUploadError,
    fileDowloadLink,
    resource,
    isFetchingDownloadLinkStarted,
    getFileDownloadError,
    orderData,
  } = useSelector(({ orderDetails }) => orderDetails);
  const { datasetList } = useSelector(({ userSubscription }) => userSubscription);
  const { guestInstituteGuid } = useSelector(({ userProfile }) => userProfile);
  const [isDocumentUploadClicked, setIsDocumentUploadClicked] = useState(false);
  const [isShowPhiModal, setIsShowPhiModal] = useState(false);
  const [documentFileName, setDocumentFileName] = useState(
    orderFormData?.documentFileName ? orderFormData?.documentFileName : '',
  );
  const [documentFile, setDocumentFile] = useState();
  const [phiDisclaimer, setPhiDisclaimer] = useState(
    orderFormData?.phiDisclaimer ? orderFormData?.phiDisclaimer : false,
  );
  const [documentError, setDocumentError] = useState('');

  const [filePath, setFilePath] = useState('');
  const [dataTypeValue, setDatatypeValue] = useState(
    orderFormData?.data_source ? orderFormData?.data_source : '',
  );
  const [sharingValue, setSharingValue] = useState(
    orderFormData?.sharing_type ? orderFormData?.sharing_type : 'Library',
  );
  const urgencyTypeList = ['Not Urgent', 'Urgent'];
  const [urgencyType, setUrgencyType] = useState(
    orderFormData?.urgencyType
      ? orderFormData?.urgencyType === true
        ? 'Urgent'
        : 'Not Urgent'
      : 'Not Urgent',
  );
  const setQuestionTypeValueFunction = (name, guid, bgColor) => {
    setQuestionTypeValue(name);
    setQuestionName(name);
    setBackgroundColor(bgColor);
    setQuestionGuid(guid);
    setQuestionTypeDropDownFlag(false);
  };
  const [isShowDataSetDropDown, setShowDataSetDropDown] = useState(false);
  const [url, setUrl] = useState('');
  const [isConfidential, setConfidential] = useState(
    orderFormData?.sharing_type
      ? orderFormData?.sharing_type === 'Personal'
        ? true
        : false
      : false,
  );

  let initialState = {
    questionText: {
      value: orderFormData?.clinicalQuestion ? orderFormData.clinicalQuestion : '',
      length: orderFormData?.clinicalQuestion
        ? orderFormData?.clinicalQuestion.length
        : 0,
    },
    population: {
      value: orderFormData?.population ? orderFormData.population : '',
      length: orderFormData?.population ? orderFormData?.population.length : 0,
    },
    intervention: {
      value: orderFormData?.intervention ? orderFormData.intervention : '',
      length: orderFormData?.intervention ? orderFormData?.intervention.length : 0,
    },
    control: {
      value: orderFormData?.control ? orderFormData.control : '',
      length: orderFormData?.control ? orderFormData?.control.length : 0,
    },
    outcome: {
      value: orderFormData?.outcome ? orderFormData.outcome : '',
      length: orderFormData?.outcome ? orderFormData?.outcome.length : 0,
    },
    timeframe: {
      value: orderFormData?.timeframe ? orderFormData.timeframe : '',
      length: orderFormData?.timeframe ? orderFormData?.timeframe.length : 0,
    },
    picotSwitch: {
      value: orderFormData?.picotSwitch ? orderFormData.picotSwitch : false,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const handleCheck = () => {
    mixpanel.track(
      'Confidential input ' + !isConfidential ? 'checked' : 'unchecked' + ' event',
    );
    setConfidential(!isConfidential);
  };

  const isFormValid = () => {
    var errorText = '';
    if (orderFormData?.firstName === '' || orderFormData?.firstName === undefined) {
      errorText = orderFormErrorStr.firstName + ' ' + Strings.orderFirstNameError;
    } else if (orderFormData?.lastName === '' || orderFormData?.lastName === undefined) {
      errorText = orderFormErrorStr.lastName + ' ' + Strings.orderLastNameError;
    } else if (
      orderFormData?.institute_name === '' ||
      orderFormData?.institute_name === undefined
    ) {
      errorText = Strings.orderOrgnisationError;
    } else if (
      orderFormData?.question_type === '' ||
      orderFormData?.question_type === undefined
    ) {
      errorText = Strings.orderQuestionTypeError;
    } else {
      errorText = '';
    }
    setFormErrors(errorText);
    if (errorText) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const getDatasetList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getSubscriptionDataset({
          token: token ? token.__raw : '',
          guid: guestInstituteGuid,
          productCaseType: orderData?.product_case_type
        }),
      );
    };
    getDatasetList();
  }, [dispatch]);

  const submitOrder = async () => {
    const token = await getIdTokenClaims();
    if (
      formState.questionText.value &&
      isFormValid() &&
      dataTypeValue !== '' &&
      !isUploadingDocument
    ) {
      dispatch(resetOrderIndexData());
      var documentPath = '';
      if (uploadedDocumentInfo?.key_path) {
        documentPath = uploadedDocumentInfo?.key_path;
      } else if (
        orderFormData?.previous_order_guid &&
        orderFormData?.documentKeyPath !== ''
      ) {
        documentPath = orderFormData?.documentKeyPath;
      } else {
        documentPath = '';
      }
      if (
        orderFormData?.previous_order_guid &&
        orderFormData?.orderStatus === 'Pending' &&
        orderFormData?.isEditOrder
      ) {
        dispatch(
          modifyPrognostogramOrder({
            params: {
              first_name: orderFormData.firstName,
              last_name: orderFormData.lastName,
              email: orderFormData.email,
              question_guid: questionGuid,
              clinical_questions: formState.questionText.value,
              question_title: formState.questionText.value,
              population: formState.population.value,
              intervention: formState.intervention.value,
              control: formState.control.value,
              outcome: formState.outcome.value,
              timeframe: formState.timeframe.value,
              picot_status: formState.picotSwitch.value,
              institute_name: orderFormData.institute_name,
              institute_guid:
                orderFormData.institute_guid === 'other'
                  ? ''
                  : orderFormData.institute_guid,
              data_source: [],
              sharing_type: sharingValue,
              confidential: isConfidential,
              is_urgent: urgencyType === 'Urgent' ? true : false,
              document: documentPath,
              phi_disclaimer: documentFileName !== '' ? phiDisclaimer : null,
            },
            orderGuid: orderFormData?.previous_order_guid,
            token: getRawToken(token),
            navigateToDashboard: navigateToDashboard,
          }),
        );
      } else {
        const dataTypeValues = dataTypeValue.split(',');
        const dataSourceArr = datasetList.filter((data) =>
          dataTypeValues.includes(data.name),
        );

        dispatch(
          orderPrognostogram({
            params: {
              first_name: orderFormData.firstName,
              last_name: orderFormData.lastName,
              email: orderFormData.email,
              physician_institute: orderFormData.physicianInstitution,
              question_guid: questionGuid,
              clinical_questions: formState.questionText.value,
              previous_order_guid: orderFormData?.previous_order_guid,
              question_title: formState.questionText.value,
              population: formState.population.value,
              intervention: formState.intervention.value,
              control: formState.control.value,
              outcome: formState.outcome.value,
              timeframe: formState.timeframe.value,
              picot_status: formState.picotSwitch.value,
              institute_name: orderFormData.institute_name,
              institute_guid:
                orderFormData.institute_guid === 'other'
                  ? ''
                  : orderFormData.institute_guid,
              data_source: dataSourceArr.length && dataSourceArr,
              sharing_type: sharingValue,
              confidential: isConfidential,
              is_urgent: urgencyType === 'Urgent' ? true : false,
              document: documentPath,
              phi_disclaimer: documentFileName !== '' ? phiDisclaimer : null,
            },
            csrfToken: Cookies.get('csrftoken'),
            token: token ? token.__raw : '',
            navigateToDashboard: navigateToDashboard,
          }),
        );
      }
    }
  };

  const navigateToDashboard = () => {
    dispatch(clearOrderFormData());
    history.push(urlConstantsEnum.ORDER_SUBMITTED);
  };

  useEffect(() => {
    questionData?.map((question) => {
      if (question.name === orderFormData?.question_type) {
        setQuestionGuid(question.guid);
        setBackgroundColor(question.style.backgroundColor);
      }
    });
    dispatch(resetData());
  }, [dispatch, orderFormData?.question_type, questionData]);

  useEffect(() => {
    if (orderFormData?.previous_order_guid && orderFormData?.isEditOrder) {
      setUrl('/edit/' + orderFormData?.previous_order_guid);
    } else if (orderFormData?.previous_order_guid && !orderFormData?.isEditOrder) {
      setUrl('/reorder/' + orderFormData?.previous_order_guid);
    } else {
      setUrl('');
    }
  }, []);

  const onClickOfBackBtn = () => {
    dispatch(setOrderFormData(orderFormDataobj));
    history.push(urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_TWO + url);
  };

  const onClickOfEditInfoBtn = () => {
    dispatch(setOrderFormData(orderFormDataobj));
    history.push(urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE + url);
  };

  let orderFormDataobj = {
    question_type: orderFormData?.question_type,
    firstName: orderFormData?.firstName,
    lastName: orderFormData?.lastName,
    email: orderFormData?.email,
    physicianInstitution: orderFormData?.physicianInstitution,
    clinicalQuestion: formState.questionText.value,
    previous_order_guid: orderFormData?.previous_order_guid,
    questionTitle: formState.questionText.value,
    population: formState.population.value,
    intervention: formState.intervention.value,
    control: formState.control.value,
    outcome: formState.outcome.value,
    timeframe: formState.timeframe.value,
    picotSwitch: formState.picotSwitch.value,
    orderStatus: orderFormData?.orderStatus,
    isEditOrder: orderFormData?.isEditOrder,
    institute_name: orderFormData?.institute_name,
    institute_guid: orderFormData?.institute_guid,
    data_source: dataTypeValue,
    sharing_type: sharingValue,
    otherOrganization: orderFormData?.otherOrganization,
    urgencyType: urgencyType === 'Urgent' ? true : false,
    phiDisclaimer: phiDisclaimer,
    documentFileName: documentFileName,
  };

  const onInputChange = (e, name) => {
    if (e.target.value.length <= INPUT_SIZE) {
      setFormState((formState) => ({
        ...formState,
        [name]: {
          ...formState[name],
          value: e.target.value,
          length: e.target.value.length,
        },
      }));
    } else {
      e.preventDefault();
    }
  };

  const handleSwitchChange = (checked) => {
    mixpanel.track('PICOT switch clicked event', {
      is_picot_option_selected: checked,
    });
    setFormState((formState) => ({
      ...formState,
      picotSwitch: {
        value: checked,
      },
    }));
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowTooltip(false);
      mixpanel.track('PICOT tooltip closed event');
    }
    if (refConfidential.current && !refConfidential.current.contains(event.target)) {
      setShowTooltipConfidential(false);
      mixpanel.track('Confidential tooltip closed event');
    }

    if (dropDownRef.current && !dropDownRef.current?.contains(event.target)) {
      setQuestionTypeDropDownFlag(false);
      mixpanel.track('Question type selection dropdown closed event');
    }
  };

  const onCancelRequest = () => {
    dispatch(resetDownloadLink());
    dispatch(resetUploadDocumentData());
    if (!isAuthenticated) {
      history.push(urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE);
    } else {
      if (location?.state?.isFromSearch) {
        history.push({
          pathname:
            orderScopeData?.scopeTabIndex === 1
              ? urlConstantsEnum.LIBRARY_SEARCH_RESULT
              : urlConstantsEnum.SEARCH_RESULT,
          state: {
            searchQuery: location?.state?.searchText,
          },
        });
      } else {
        history.push(
          orderScopeData?.scopeTabIndex === 1
            ? urlConstantsEnum.LIBRARY_DASHBOARD
            : urlConstantsEnum.DASHBOARD,
        );
      }
    }
  };

  const setAdvacedSettingBlock = () => {
    mixpanel.track(
      'Advanced setting option ' + !isButtonClicked ? 'opened' : 'closed' + ' event',
    );
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
  };

  useEffect(() => {
    if (datasetList?.length > 0) {
      if (dataTypeValue === '') {
        setDatatypeValue(datasetList[0].name);
      }
    }
  }, [datasetList]);

  const handleDocumentUpload = () => {
    mixpanel.track(
      'Document upload option ' + !isDocumentUploadClicked
        ? 'opened'
        : 'closed' + ' event',
    );
    if (!isDocumentUploadClicked) {
      setIsDocumentUploadClicked(true);
    } else {
      setIsDocumentUploadClicked(false);
    }
  };

  const OnClickOfLogin = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}`,
      appState: { returnTo: window.location.pathname },
    });
  };

  const handleDocumentInputUpload = (e) => {
    if (e.target.files[0]) {
      var supportedExtensions = /(\.docx|\.pdf|\.csv|\.xlsx|\.pptx)$/i; //supported extensions
      var maxfilesize = 1024 * 1024 * 25; //Allowing 25MB
      var filesize = e.target.files[0].size;
      if (filesize > maxfilesize) {
        setDocumentError(Strings.documentSizeError);
      } else if (!supportedExtensions.exec(e.target.files[0].name)) {
        setDocumentError(Strings.documentExtensionError);
      } else {
        setDocumentError('');
        setIsShowPhiModal(true);
        setDocumentFile(e.target.files[0]);
        setDocumentFileName(e.target.files[0].name);
      }
    } else {
      setIsShowPhiModal(false);
      resetDocument();
    }
  };

  const resetDocument = () => {
    setDocumentFileName('');
    setDocumentFile(null);
    setDocumentError('');
  };

  const onClickOfDeleteDocument = () => {
    dispatch(resetUploadDocumentData());
    resetDocument();
  };

  const OnClickOfPhiModalClose = () => {
    setIsShowPhiModal(false);
    setPhiDisclaimer(false);
    if (orderFormData?.previous_order_guid && orderFormData?.documentFileName !== '') {
      setDocumentFileName(orderFormData?.documentFileName);
    } else {
      resetDocument();
    }
  };

  const OnClickOfPhiModalConfirm = () => {
    setIsShowPhiModal(false);
    setPhiDisclaimer(true);
    uploadDocument();
  };

  const uploadDocument = async () => {
    var data = new FormData();
    data.append('file', documentFile);
    const token = await getIdTokenClaims();
    if (token) {
      dispatch(
        uploadOrderDocument({
          token: getRawToken(token),
          params: data,
          tokenExpiredCallback: redirectToLogin,
        }),
      );
    } else {
      redirectToLogin();
    }
  };

  const getDocument = async () => {
    getDownloadUrl(orderFormData?.previous_order_guid, resourceEnum.DOCUMENT);
  };

  const redirectToLogin = () => {
    history.push(urlConstantsEnum.LOGIN);
  };

  useEffect(() => {
    if (
      fileDowloadLink !== undefined &&
      fileDowloadLink !== '' &&
      resource === resourceEnum.DOCUMENT
    ) {
      window.open(fileDowloadLink);
      dispatch(resetDownloadLink());
    }
  }, [fileDowloadLink]);

  const setDataSourceChecked = (value) => {
    if (dataTypeValue) {
      var dataSourceValues = dataTypeValue.split(',');
      var index = dataSourceValues.indexOf(value);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleDataSourceChange = (e) => {
    var dataSourceValues = dataTypeValue !== '' ? dataTypeValue.split(',') : [];
    datasetList?.map((source) => {
      if (e.target.value === source.name) {
        if (e.target.checked) {
          dataSourceValues.push(e.target.value);
        } else {
          var index = dataSourceValues.indexOf(e.target.value);
          if (index > -1) {
            dataSourceValues.splice(index, 1);
          }
        }
      }
    });
    mixpanel.track('Data source selected event', {
      idata_source_selected_value: dataSourceValues.join(),
    });
    setDatatypeValue(dataSourceValues.join());
  };

  const getDataSourceList = () => {
    const listItems = datasetList?.map((source, index) => (
      <div className="d-flex align-items-center my-3" key={index + '-' + source.name}>
        <li className="d-flex flex-content-center color-black li-list-specialty">
          <Checkbox
            buttonName={source.name}
            onChange={handleDataSourceChange}
            name={'dataSource'}
            value={datasetList[index].name}
            checked={setDataSourceChecked(source.name)}
            id={'checkbox-data-source-selection'}
          />
        </li>
        <div className="ml-2 clickable tooltip-icon">
          <ImInfo size={14} />
          <span className="tooltip-text">{datasetList[index].description}</span>
        </div>
      </div>
    ));
    return <ul className="">{listItems}</ul>;
  };

  useEffect(() => {
    function handler(event) {
      if (!dataSourceDivRef.current?.contains(event.target)) {
        setShowDataSetDropDown(false);
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, []);

  const onClickOfDataSource = () => {
    if (
      orderFormData?.previous_order_guid &&
      orderFormData?.orderStatus === 'Pending' &&
      orderFormData?.isEditOrder
    ) {
      setShowDataSetDropDown(false);
    } else {
      mixpanel.track('Data set dropdown clicked event');
      setShowDataSetDropDown(!isShowDataSetDropDown);
    }
  };

  useEffect(() => {
    const getProfileData = async () => {
      const token = await getIdTokenClaims();
      if (token) {
        dispatch(
          requestGetUserProfile({
            token: getRawToken(token),
          }),
        );
      }
    };
    getProfileData();
  }, []);

  const onClickOfUrgencyButton = (e) => {
    mixpanel.track('Urgency of request options clicked', {
      selected_urgency_value: e.target.value,
    });
    setUrgencyType(e.target.value);
  };

  const getUrgencyOfRequestList = () => {
    const listItems = urgencyTypeList?.map((source, index) => (
      <div className={`radio-input d-flex flex-self-center`} key={index + '-' + source}>
        <label className="d-flex flex-self-center">
          <input
            id={'not-urgent'}
            type="radio"
            name={'urgencyList'}
            value={urgencyTypeList[index]}
            onClick={onClickOfUrgencyButton}
            className="mr-3"
            checked={urgencyType === source ? true : false}
            id={`radio-button-urgency-${index}`}
            readOnly
          />
          <div className="d-flex flex-self-center flex-items-center">
            <span>{source} &nbsp;</span> {source === 'Urgent' ? '< ' : '> '}48hrs
          </div>
        </label>
      </div>
    ));
    return (
      <div className="mb-4 urgency-container">
        <div className="advanced-setting-title mb-2">Urgency of Request</div>
        <div className="d-md-flex">{listItems}</div>
      </div>
    );
  };

  return isOrderLoading ? (
    <AuthLoader fullScreen={true} />
  ) : (
    <div className="order-container pb-3">
      <OrderPrognostogramHeader
        headerArray={[
          { name: orderHeadereEnum.PROFILE_INFO, isActive: false, isCompleted: true },
          { name: orderHeadereEnum.QUESTION_TYPE, isActive: false, isCompleted: true },
          {
            name: orderHeadereEnum.CLINICAL_QUESTION,
            isActive: true,
            isCompleted: false,
          },
        ]}
        onClickOfBackBtn={onClickOfBackBtn}
        headerText={
          orderFormData?.previous_order_guid && orderFormData?.isEditOrder
            ? 'Edit Order'
            : orderFormData?.previous_order_guid && !orderFormData?.isEditOrder
            ? 'Modify and Reorder'
            : 'Request a Prognostogram'
        }
        hideHeaderSections={!orderFormData?.previous_order_guid}
      />
      <CustomModal
        isOpen={isShowPhiModal}
        handleClose={OnClickOfPhiModalClose}
        cssClass="white-overlay"
        modalname="PHI modal"
        overlayCssClass={'phi-modal-overlay'}
      >
        <div className="email-modal p-5 delete-modal phi-modal">
          <div className="profile-sub-header mt-4 mb-2">{Strings.phiModalText1}</div>
          <div className="d-md-flex flex-justify-end">
            <ButtonCustom
              cssClass="plain-btn mt-5 px-4"
              onClick={() => OnClickOfPhiModalClose()}
              buttonAction={'Cancel PHI button clicked'}
              actionLabel={'Cancel document contains PHI'}
              id={'btn-cancel-phi-button'}
            >
              Cancel
            </ButtonCustom>
            <ButtonCustom
              cssClass="btn-primary mt-2 mt-md-5 px-4 ml-md-3"
              onClick={() => OnClickOfPhiModalConfirm()}
              buttonAction={'PHI confirm button clicked'}
              actionLabel={'Confirm document does not have PHI'}
              id={'btn-confirm-phi-button'}
            >
              Confirm
            </ButtonCustom>
          </div>
        </div>
      </CustomModal>
      <div className="order-section-container clinical-question-container mb-3 pb-5">
        {orderFormData?.previous_order_guid ? (
          <div>
            <div className="d-flex flex-justify-end mt-4">
              <ButtonSecondary
                label="EDIT INFO"
                cssClass="edit-info-text"
                onClick={onClickOfEditInfoBtn}
                id={'btn-edit-info-button'}
                buttonAction={'Edit profile info button clicked'}
              />
            </div>
            <div className="edit-info-container mt-2">
              <div className="d-md-flex">
                <div className="mr-md-6 mb-3 mb-md-0">
                  <div className="info-label">First Name</div>
                  <div className="info-value">{orderFormData.firstName}</div>
                </div>
                <div className="mb-3 mb-md-0">
                  <div className="info-label">Last Name</div>
                  <div className="info-value">{orderFormData.lastName}</div>
                </div>
              </div>
              <div className="d-md-flex mt-md-4">
                <div className="mr-md-5 mb-3 mb-md-0">
                  <div className="info-label">Email</div>
                  <div className="info-value">{orderFormData.email}</div>
                </div>
                <div>
                  <div className="info-label">Physician Institution</div>
                  <div className="info-value">{orderFormData.physicianInstitution}</div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-self-center position-relative mt-4">
              <span className="question-label">Question Type</span>
            </div>
          </div>
        ) : (
          ''
        )}
        <div ref={dropDownRef}>
          <div
            className="d-flex questionTypeDiv flex-justify-between px-3 px-md-5 py-3 mt-4"
            style={{ backgroundColor: questionBackgroundColor }}
          >
            <div className="d-flex question-name flex-items-center">
              <span className="question-check mr-3">
                <ImCheckmark />
              </span>
              {questionTypeValue ? questionName : orderFormData?.question_type}
            </div>
            {(orderFormData?.previous_order_guid && !orderFormData?.isEditOrder) ||
            (orderFormData?.previous_order_guid && orderFormData?.isEditOrder) ? (
              <ButtonSecondary
                label="Change"
                cssClass="change-btn"
                id={'btn-question-type-selection-button'}
                onClick={() => {
                  setQuestionTypeDropDownFlag(!isQuestionTypeDropDownFlag);
                  mixpanel.track(
                    'Question type selection dropdown ' + isQuestionTypeDropDownFlag
                      ? 'opened'
                      : 'closed' + ' event',
                  );
                }}
                buttonAction="Change question type button clicked"
              />
            ) : (
              <ButtonSecondary
                label="Change"
                cssClass="change-btn"
                onClick={onClickOfBackBtn}
                id={'btn-back-button'}
                buttonAction="Change order info button clicked"
              />
            )}
          </div>
          {isQuestionTypeDropDownFlag ? (
            <div className="d-flex flex-justify-between questionType questionType-drop-down mt-2">
              <ul className="questionType questionType-dropdown-select">
                {questionData?.map((question, index) => (
                  <li
                    className="hover-option option-list mx-1 my-1"
                    onChange={() => setQuestionTypeDropDownFlag(true)}
                    style={{ backgroundColor: question.style.backgroundColor }}
                    key={index}
                    id={`list-question-type-${question.name}`}
                    onClick={() =>
                      setQuestionTypeValueFunction(
                        question.name,
                        question.guid,
                        question.style.backgroundColor,
                      )
                    }
                  >
                    <span className="ml-3">{question.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="d-flex flex-justify-between questionType add-margin"></div>
          )}
        </div>
        <div className="d-flex flex-justify-between my-3 position-relative">
          <div className="d-flex flex-self-center position-relative">
            <span className="question-label">Your Question</span>
          </div>
          <div className="d-flex">
            <div className="d-flex picot-switch">
              <span className="flex-self-center picot-span d-flex">PICOT</span>
              <div className="tooltip-container flex-self-center">
                <div
                  className={showTooltip ? 'tooltip-box visible' : 'tooltip-box'}
                  ref={ref}
                >
                  <span className="font-bold">P</span>opulation,{' '}
                  <span className="font-bold">I</span>ntervention,{' '}
                  <span className="font-bold">C</span>ontrol,
                  <span className="font-bold">O</span>utcome,{' '}
                  <span className="font-bold">T</span>imeframe
                  <div
                    className="mt-2"
                    id={'picot-tooltip-option'}
                    onClick={() => {
                      setShowTooltip(false);
                      mixpanel.track('PICOT tooltip closed event');
                    }}
                  >
                    For more information on how to ask a well structured PICOT question,{' '}
                    <a
                      href={PICOT_QUESTION_LINK}
                      target="_blank"
                      rel="noreferrer"
                      id={'picot-question-link'}
                    >
                      click here
                    </a>
                  </div>
                  <span className="tooltip-arrow" />
                </div>
                <div
                  className="tooltip-button d-flex"
                  id={'picot-tooltip'}
                  onMouseEnter={() => {
                    setShowTooltip(true);
                    mixpanel.track('PICOT tooltip opened event');
                  }}
                >
                  <ImInfo />
                </div>
              </div>
              <Switch
                checkedIcon={false}
                onColor="#000000"
                offColor="#F7F7F7"
                height={20}
                width={30}
                uncheckedIcon={false}
                onChange={handleSwitchChange}
                checked={formState.picotSwitch.value}
                className="flex-self-center"
                id={'picot-switch'}
              />
            </div>
          </div>
        </div>
        {formState.picotSwitch.value ? (
          <div className="picot-container">
            <div className="picot-lable mb-2">Question title</div>
            <TextArea
              value={formState.questionText.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'questionText')}
              id={'textarea-question-text'}
            />
            <div className="text-count">
              {formState.questionText.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Population</div>
            <TextArea
              value={formState.population.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'population')}
              id={'textarea-population'}
            />
            <div className="text-count">
              {formState.population.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Intervention</div>
            <TextArea
              value={formState.intervention.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'intervention')}
              id={'textarea-intervention'}
            />
            <div className="text-count">
              {formState.intervention.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Control</div>
            <TextArea
              value={formState.control.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'control')}
              id={'textarea-control'}
            />
            <div className="text-count">
              {formState.control.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Outcome</div>
            <TextArea
              value={formState.outcome.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'outcome')}
              id={'textarea-outcome'}
            />
            <div className="text-count">
              {formState.outcome.length}/{INPUT_SIZE}
            </div>
            <div className="picot-lable mb-2">Timeframe</div>
            <TextArea
              value={formState.timeframe.value}
              rows={2}
              onChange={(e) => onInputChange(e, 'timeframe')}
              id={'textarea-timeframe'}
            />
            <div className="text-count">
              {formState.timeframe.length}/{INPUT_SIZE}
            </div>
          </div>
        ) : (
          <div>
            <TextArea
              placeholder="Write your clinical question here.."
              value={formState.questionText.value}
              rows={14}
              onChange={(e) => onInputChange(e, 'questionText')}
              id={'textarea-clinical-question'}
            />
            <div className="text-count">
              {formState.questionText.length}/{INPUT_SIZE}
            </div>
          </div>
        )}
        {getUrgencyOfRequestList()}

        <div>
          <div className="advaced-settings-div mb-4">
            <div
              className="d-flex cursor-pointer"
              onClick={() => handleDocumentUpload()}
              id={'document-upload-option'}
            >
              <div className="advanced-setting-title">Document Upload</div>
              <div
                className={
                  !isDocumentUploadClicked
                    ? 'button-position right-arrow'
                    : 'button-position right-arrow rotate-button'
                }
              >
                <ButtonLink
                  buttonAction="Document Upload section clicked"
                  id={'document-upload-icon'}
                >
                  <HiOutlineChevronRight />
                </ButtonLink>
              </div>
            </div>
            {isDocumentUploadClicked ? (
              <>
                {!isUploadingDocument ? (
                  <>
                    <div
                      className={`advanced-setting-body mt-2 ${
                        !isAuthenticated ? 'disbaled-document-upload' : ''
                      }`}
                    >
                      {documentFileName !== '' ? (
                        <>
                          {orderFormData?.previous_order_guid &&
                          orderFormData?.documentFileName !== '' &&
                          orderFormData?.documentFileName === documentFileName ? (
                            <>
                              {isFetchingDownloadLinkStarted ? (
                                <div className="loader-btn-view">
                                  <AuthLoader fullScreen={false} />
                                </div>
                              ) : (
                                <ButtonCustom
                                  cssClass="no-file mt-4 text-left text-decoration-underline cursor-pointer"
                                  onClick={() => getDocument()}
                                >
                                  {documentFileName}
                                </ButtonCustom>
                              )}
                              {getFileDownloadError &&
                              resource === resourceEnum.DOCUMENT ? (
                                <div className="document-format-text my-3 document-error d-flex flex-items-center">
                                  <MdError />
                                  <div className="ml-2">{getFileDownloadError}</div>
                                </div>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            <div className="no-file mt-4">{documentFileName}</div>
                          )}
                        </>
                      ) : (
                        <div className="no-file mt-4">No file selected</div>
                      )}
                      {documentError !== '' || getDocumentUploadError !== undefined ? (
                        <div className="document-format-text my-3 document-error d-flex flex-items-center">
                          <MdError />
                          <div className="ml-2">
                            {documentError !== ''
                              ? documentError
                              : getDocumentUploadError}
                          </div>
                        </div>
                      ) : (
                        <div className="document-format-text my-3">
                          {Strings.documentExtensionNote}
                        </div>
                      )}
                      {documentFileName !== '' && !orderFormData?.previous_order_guid ? (
                        <>
                          <ButtonCustom
                            disabled={!isAuthenticated}
                            cssClass="btn-primary"
                            onClick={() => onClickOfDeleteDocument()}
                            buttonAction={'Delete document button clicked'}
                            actionLabel={'Deleted selected document'}
                            id={'btn-delete-document-button'}
                          >
                            Delete
                          </ButtonCustom>
                        </>
                      ) : (
                        <>
                          {isAuthenticated ? (
                            <input
                              disabled={!isAuthenticated}
                              type="file"
                              name="document"
                              id="document-upload"
                              className="inputfile d-none"
                              accept=".docx,.pdf,.csv,.xlsx,.pptx"
                              value={filePath}
                              onChange={(e) => {
                                handleDocumentInputUpload(e);
                              }}
                              onClick={() => {
                                setFilePath('');
                              }}
                            />
                          ) : (
                            ''
                          )}
                          <label htmlFor="document-upload" className="inputfile-label">
                            {orderFormData?.previous_order_guid && documentFileName !== ''
                              ? 'Replace'
                              : 'Choose'}
                          </label>
                        </>
                      )}
                    </div>
                    {!isAuthenticated ? (
                      <div className="document-disabled-text">
                        To upload a document, please login or create an account{' '}
                        <ButtonCustom
                          onClick={() => OnClickOfLogin()}
                          cssClass="mt-2 login-btn"
                          buttonAction="Sign in button clicked"
                          id={'btn-login-button'}
                        >
                          here
                        </ButtonCustom>
                        .
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <div className="loader-btn-view">
                    <AuthLoader fullScreen={false} />
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>

        <div>
          <div className="advaced-settings-div mb-4">
            <div
              className="d-flex cursor-pointer"
              onClick={() => setAdvacedSettingBlock()}
              id={'advanced-setting-optionn'}
            >
              <div className="advanced-setting-title">Advanced Settings </div>
              <div
                className={
                  !isButtonClicked
                    ? 'button-position right-arrow'
                    : 'button-position right-arrow rotate-button'
                }
              >
                <ButtonLink
                  buttonAction="Advanced setting clicked"
                  id={'advaced-setting-icon'}
                >
                  <HiOutlineChevronRight />
                </ButtonLink>
              </div>
            </div>
            {isButtonClicked ? (
              <div className="advanced-setting-body mt-2">
                <div
                  className={`w-50 data-source-container mb-3 position-relative ${
                    orderFormData?.previous_order_guid &&
                    orderFormData?.orderStatus === 'Pending' &&
                    orderFormData?.isEditOrder
                      ? 'disabled-data'
                      : ''
                  }`}
                >
                  <div className="heading-text mb-3">Data Sources</div>
                  <div className="mb-2 sub-heading-text">
                    To learn more about our data sources,{' '}
                    <a
                      className=""
                      href={'mailto:support@atroposhealth.com'}
                      id={'email-click-here'}
                    >
                      click here
                    </a>
                  </div>
                  <div ref={dataSourceDivRef}>
                    <div
                      className="position-relative"
                      onClick={() => onClickOfDataSource()}
                      id={'data-source-selection-dropdown'}
                    >
                      <div
                        className={`data-source ${
                          dataTypeValue === '' ? 'placeholder-color' : ''
                        }`}
                      >
                        {dataTypeValue === '' ? 'Select data source' : dataTypeValue}
                      </div>
                      <ButtonLink
                        cssClass="position-absolute"
                        buttonAction="Select data source clicked"
                        id={'data-source-dropdown-arrow'}
                      >
                        <HiOutlineChevronDown />
                      </ButtonLink>
                    </div>
                    {isShowDataSetDropDown ? (
                      <div className="d-flex-toggle d-flex flex-justify-center data-source-list mt-1 position-absolute">
                        {getDataSourceList()}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="display-advaced-settings-block mt-4">
                  <div className="heading-text mt-3 mb-2">Sharing</div>
                  <div className="button-container d-md-flex">
                    <div className={`toolTip-div pr-4 `} key={'index'}>
                      <Checkbox
                        buttonName={'Confidential'}
                        onChange={handleCheck}
                        name={'sharing-1'}
                        value={isConfidential}
                        checked={isConfidential ? true : false}
                        id={'checkbox-confidential-sharing-setting'}
                      />
                      <div
                        className="tooltip-container container-tooltip-box"
                        key={'index'}
                      >
                        <div
                          className={
                            showTooltipConfidential
                              ? 'tooltip-box visible set-div-width'
                              : 'tooltip-box'
                          }
                          ref={refConfidential}
                        >
                          <div
                            className="mx-1 my-1"
                            onClick={() => {
                              setShowTooltipConfidential(false);
                              mixpanel.track('Confidential tooltip closed event');
                            }}
                          >
                            By checking this box, you are choosing to keep the question
                            and resulting Prognostogram confidential for 30 days. After 30
                            days, Atropos may share the resulting Prognostogram with
                            authorized users based on your organization's preferences.
                            Please contact us at{' '}
                            <a className="" href="mailto:support@atroposhealth.com">
                              support@atroposhealth.com
                            </a>{' '}
                            if you have any questions.
                          </div>
                          <span className="tooltip-arrow" />
                        </div>
                        <div
                          className="tooltip-button d-md-flex my-2"
                          onMouseEnter={() => {
                            setShowTooltipConfidential(true);
                            mixpanel.track('Confidential tooltip opened event');
                          }}
                          onClick={() => {
                            setShowTooltipConfidential(!showTooltipConfidential);
                            mixpanel.track(
                              'Confidential tooltip ' + showTooltipConfidential
                                ? 'opened'
                                : 'closed' + ' event',
                            );
                          }}
                        >
                          <ImInfo />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="d-md-flex flex-md-justify-between">
          <ButtonCustom
            cssClass={`gradient-btn send-request-btn mt-md-0 mt-3 ${
              !formState.questionText.value ||
              dataTypeValue === '' ||
              (isUploadingDocument && documentFileName !== '')
                ? 'btn-disabled'
                : ''
            }`}
            onClick={submitOrder}
            buttonAction={'Submit order button clicked'}
            actionLabel={'New PG order is submitted'}
            id={'btn-submit-order-button'}
          >
            Confirm and Send Request
          </ButtonCustom>
          <ButtonCustom
            cssClass="cancel-btn mt-md-0 mt-3"
            onClick={onCancelRequest}
            id={'btn-cancel-order-button'}
            buttonAction={
              orderFormData?.previous_order_guid && orderFormData?.isEditOrder
                ? 'Discard Changes edit order clicked'
                : 'Cancel new PG order clicked'
            }
            actionLabel={
              orderFormData?.previous_order_guid && orderFormData?.isEditOrder
                ? 'Edit order changes discarded'
                : 'New PG order canceled'
            }
          >
            {orderFormData?.previous_order_guid && orderFormData?.isEditOrder
              ? 'Discard Changes'
              : 'Cancel Request'}
          </ButtonCustom>
        </div>
        {!!orderPrognostogramError || formErrors !== '' ? (
          <div className="color-error error-text">
            {formErrors ? formErrors : orderPrognostogramError}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default ClinicalQuestionForm;
