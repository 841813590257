import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import ClinicalQuestionForm from './ClinicalQuestionForm';
import ManualEnterStudyFormat from './ManualEnterStudyFormat';
import { useOrdersPagedata } from '../OrderPageContext';
import QuestionTitleHeader from './QuestionTitleHeader';
import StudyDetails from './StudyDetails';
import SkeletonLoaders from './SkeletonLoaders';
import {
  orderPrognostogram,
  resetOrderIndexData,
} from 'redux/modules/orderDetails/actions';
import Phenotype from './phenotype/Phenotype';
import StudyForm from './StudyForm';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import FormButtons from '../FormButtons';
import DatasetSelect from './DatasetSelect';
import MotivationSelect from './MotivationSelect';
import GeneralStudyParametersForm from 'components/GeneralStudyParametersForm';

const customSuggestionsButtons = {
  INCORPORATE_CHANGES: 'Incorporate Changes',
  KEEP_ORIGINAL: 'Keep Original',
  REGENERATE_SUGGESTIONS: 'Regenerate Suggestions',
  START_OVER: 'Start Over',
};

function ChartRwdOrder({ onOrderSuccessCallbackForChatrwd }) {
  const dispatch = useDispatch();
  const [generalStudyParams, setGeneralStudyParams] = useState();

  const {
    ordersPageData: { chatRWD, loadingTitle, errorsObject },
    dispatchOrderPageAction,
    isChatRWDFormValid,
    orderPayloadData,
    handleButtonAction,
    token,
    hasZeroOutcomeMaxValue,
  } = useOrdersPagedata();
  const {
    isManualInputMode,
    isManualEnterFromPhenotype,
    hasWorkflowStarted,
    hasUserSelectedManualWorkflow,
    selectedSuggestionsOptions,
    questionTitle,
    initialClinicalQuestions,
    isPICOTFinalized,
    isPhenotypeFinalized,
    errors,
    selectedDataSourceOption,
    selectedProviderMotivation,
  } = chatRWD;

  const {
    isOpenAiLoading,
    studyFormats,
    PICOGuid,
    phenotypeResponse,
    aIResponseErrors,
    orderPrognostogramError,
    picoSummaryQuestion,
    studyFormat,
    orderTaskStatusError,
  } = useSelector(({ orderDetails }) => orderDetails);

  const isPhenotypeResponse =
    phenotypeResponse && Object.keys(phenotypeResponse).length > 0;

  const chatRWDWorkflowErrors = {
    ...errors,
    firstName: errorsObject?.firstName,
    lastName: errorsObject?.lastName,
    email: errorsObject?.email,
  };

  const hasErrors = Object.values(chatRWDWorkflowErrors).some(
    (value) => value && value !== '',
  );

  const allDataSetUnchecked =
    selectedDataSourceOption && selectedDataSourceOption.every((opt) => !opt.isChecked);

  const isSubmitButtonDisabled =
    !questionTitle ||
    !picoSummaryQuestion ||
    selectedDataSourceOption.length === 0 ||
    !selectedProviderMotivation?.label ||
    allDataSetUnchecked ||
    !isPhenotypeFinalized ||
    !generalStudyParams?.follow_up_max_value ||
    (generalStudyParams?.downsample && generalStudyParams?.downsample_number <= 99) ||
    hasZeroOutcomeMaxValue;

  function handleOptionClick(data) {
    handleButtonAction(data);
  }

  function handleSubmitOrder() {
    if (isChatRWDFormValid() && !hasErrors) {
      dispatch(resetOrderIndexData());
      dispatchOrderPageAction({
        type: 'order/seIsOrderSubmitted',
        payload: true,
      });

      const order = {
        ...orderPayloadData,
        order: {
          ...orderPayloadData?.order,
          initial_clinical_questions: initialClinicalQuestions,
          clinical_questions: picoSummaryQuestion,
          question_title: questionTitle,
          population: studyFormat?.population ?? '',
          intervention: studyFormat?.intervention ?? '',
          control: studyFormat?.control ?? '',
          outcome: studyFormat?.outcomes || studyFormat?.outcome || '',
          timeframe: studyFormat?.timeframe ?? '',
          product_case_type: 'ChatRWD',
          pico_guid: PICOGuid,
          general_study_params: generalStudyParams,
          user_phenotypes: phenotypeResponse?.phenotypes,
        },
      };

      dispatch(
        orderPrognostogram({
          params: order,
          csrfToken: Cookies.get('csrftoken'),
          token: token || '',
          isChatrwd: true,
          navigateToDashboard: onOrderSuccessCallbackForChatrwd,
        }),
      );
    }
  }

  function handleFormActions(action) {
    const option = { key: action };
    handleButtonAction(option);
  }

  function handleGeneralStudyFormParamValues(values) {
    setGeneralStudyParams(values);
  }

  return (
    <>
      <div className="pb-2 mb-3 mt-4">
        ChatRWD only currently supports cohort studies (link to Atropos study methods
        here). Please submit your cohort study question below.
      </div>
      {!isManualEnterFromPhenotype ? (
        !hasWorkflowStarted ? (
          !isManualInputMode ? (
            <ClinicalQuestionForm />
          ) : (
            <ManualEnterStudyFormat />
          )
        ) : (
          <>
            {!hasUserSelectedManualWorkflow && <QuestionTitleHeader />}

            {studyFormats &&
              studyFormats.length > 0 &&
              studyFormats?.map((studyFormat, index) => (
                <StudyDetails
                  key={index}
                  studyFormatObject={studyFormat}
                  customButtons={customSuggestionsButtons}
                  dispatch={dispatchOrderPageAction}
                  onOptionClick={handleOptionClick}
                  multiSelect={studyFormat?.multiSelect}
                  selectedSuggestionsOptions={selectedSuggestionsOptions[index]}
                  index={index}
                />
              ))}

            {isPICOTFinalized && !aIResponseErrors?.openAiPhenotypeError && (
              <MotivationSelect />
            )}

            {isPhenotypeResponse && !aIResponseErrors?.openAiPhenotypeError && (
              <Phenotype />
            )}

            {isPhenotypeFinalized && <DatasetSelect />}

            {selectedDataSourceOption.length > 0 && !allDataSetUnchecked && (
              <div
                className="ai-worflow-container"
                data-testid="study-parameters-section"
              >
                <GeneralStudyParametersForm
                  setGeneralStudyFormParams={handleGeneralStudyFormParamValues}
                />
              </div>
            )}

            {isOpenAiLoading && <SkeletonLoaders loadingTitle={loadingTitle} />}
          </>
        )
      ) : (
        <StudyForm
          studyFormat={studyFormat}
          onCancel={() => handleFormActions(aiButtonsEnum.CANCEL_PICO_EDIT)}
          onFormSubmit={() => handleFormActions(aiButtonsEnum.SAVE_PHENOTYPE_TO_PICO)}
        />
      )}

      {aIResponseErrors &&
        Object.keys(aIResponseErrors).map((key) => (
          <div key={key} className="color-error error-text">
            {aIResponseErrors[key]}
          </div>
        ))}

      <div className="my-md-6 my-2"></div>

      {!isManualEnterFromPhenotype && !isManualInputMode && (
        <FormButtons
          disabled={isSubmitButtonDisabled}
          onSubmit={handleSubmitOrder}
          buttonAction="Submit order button clicked, order type PG"
          actionLabel="New ChatRWD order is submitted"
          id="btn-submit-order-button"
        />
      )}

      {hasErrors && (
        <ul className="ah-alert ah-alert-error list-bullet order-page-error-container">
          {Object.entries(chatRWDWorkflowErrors).map(
            ([key, value]) =>
              value && (
                <li
                  key={`${key}-${value}`}
                  className="color-error txt size-13 list-spacing"
                >
                  {value}
                </li>
              ),
          )}
        </ul>
      )}

      {!!orderPrognostogramError || !!orderTaskStatusError ? (
        <div className="color-error error-text mt-3">
          {orderPrognostogramError || orderTaskStatusError}
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default ChartRwdOrder;
