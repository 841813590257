import Checkbox from 'components/form-input/Checkbox';
import RangeSlider from 'components/form-input/RangeSlider';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPhenotypeData } from 'redux/modules/orderDetails/actions';

let initialState = {
  ageRange: {
    minValue: 18,
    maxValue: 90,
    min: 0,
    max: 100,
  },
};

function AgeRange({ phenotype }) {
  const dispatch = useDispatch();
  const {
    phenotypeResponse: { phenotypes },
  } = useSelector(({ orderDetails }) => orderDetails);
  const studyParams = phenotypes[phenotype]?.study_params;

  const { ageRange } = initialState;
  const {
    age_max = ageRange?.maxValue,
    age_min = ageRange.minValue,
    sexes = [],
  } = studyParams || {};

  const normalizedSexes = !sexes ? [] : typeof sexes === 'string' ? [sexes] : sexes;

  const ageMaxValue = age_max
    ? age_max === 'max'
      ? ageRange?.maxValue
      : age_max
    : ageRange?.maxValue;

  const handleInputChange = (e) => {
    const { maxValue, minValue } = e;
    if (age_min !== e.minValue || ageMaxValue !== e.maxValue) {
      const phenotypeData = structuredClone(phenotypes);
      const studyParams = phenotypeData[phenotype].study_params || {};
      const updatedStudyParams = {
        ...studyParams,
        age_max: maxValue,
        age_min: minValue,
      };

      phenotypeData[phenotype].study_params = updatedStudyParams;
      dispatch(setPhenotypeData(phenotypeData));
    }
  };

  function handleCheckboxChange(event) {
    const { value, checked } = event.target;
    const phenotypeData = structuredClone(phenotypes);

    const studyParams = phenotypeData[phenotype]?.study_params?.sexes || [];
    const updatedSexes = checked
      ? [...studyParams, value]
      : studyParams.filter((v) => v !== value);

    phenotypeData[phenotype].study_params = {
      ...phenotypeData[phenotype]?.study_params,
      sexes: updatedSexes,
    };
    dispatch(setPhenotypeData(phenotypeData));
  }

  return (
    <>
      <div>
        <div className="input-heading-label">
          Age Range - please select the MIN and MAX ages for your study
        </div>
        <RangeSlider
          min={ageRange.min}
          max={ageRange.max}
          minValue={age_min}
          maxValue={ageMaxValue}
          name="ageRange"
          step={1}
          handleInputChange={handleInputChange}
        />
      </div>

      <div>
        <div className="input-heading-label mt-5">
          Sex - please select the sexes to include
        </div>
        <ul className="my-2">
          <li>
            <Checkbox
              buttonName="Female"
              onChange={handleCheckboxChange}
              name="female"
              value="female"
              checked={normalizedSexes.includes('female')}
              id="checkbox-filter-female"
            />
          </li>
          <li>
            <Checkbox
              buttonName="Male"
              onChange={handleCheckboxChange}
              name="male"
              value="male"
              checked={normalizedSexes.includes('male')}
              id="checkbox-filter-male"
            />
          </li>
        </ul>
      </div>

      {/* <div>
        <div className="input-heading-label ">
          Race - please select the races to include
        </div>

        <ul>
          <li>
            <Checkbox
              buttonName="White"
              onChange={handleRaceCheckboxChange}
              name="white"
              value="white"
              checked={races.includes('white')}
              id="checkbox-filter-female"
            />
          </li>
          <li>
            <Checkbox
              buttonName="Black"
              onChange={handleRaceCheckboxChange}
              name="black"
              value="black"
              checked={races.includes('black')}
              id="checkbox-filter-male"
            />
          </li>
          <li>
            <Checkbox
              buttonName="Asian"
              onChange={handleRaceCheckboxChange}
              name="asian"
              value="asian"
              checked={races.includes('asian')}
              id="checkbox-filter-male"
            />
          </li>
          <li>
            <Checkbox
              buttonName="Other"
              onChange={handleRaceCheckboxChange}
              name="other"
              value="other"
              checked={races.includes('other')}
              id="checkbox-filter-male"
            />
          </li>
        </ul>
      </div> */}
    </>
  );
}

export default AgeRange;
