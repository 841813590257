export const INITIATE_USER_SUBSCRIPTION = 'userSubscription/initiateUserSubscription';
export const INITIATE_USER_SUBSCRIPTION_START =
  'userSubscription/initiateUserSubscriptionStart';
export const INITIATE_USER_SUBSCRIPTION_COMPLETE =
  'userSubscription/initiateUserSubscriptionCompleted';
export const INITIATE_USER_SUBSCRIPTION_ERROR =
  'userSubscription/initiateUserSubscriptionError';

export const GET_STRIPE_CONFIG = 'userSubscription/getStripeConfig';
export const GET_STRIPE_CONFIG_START = 'userSubscription/getStripeConfigStart';
export const GET_STRIPE_CONFIG_COMPLETE = 'userSubscription/getStripeConfigCompleted';
export const GET_STRIPE_CONFIG_ERROR = 'userSubscription/getStripeConfigError';

export const GET_SUBSCRIPTION_DATASET = 'userSubscription/getSubscriptionDataset';
export const GET_SUBSCRIPTION_DATASET_START = 'userSubscription/getSubscriptionDatasetStart';
export const GET_SUBSCRIPTION_DATASET_COMPLETE = 'userSubscription/getSubscriptionDatasetCompleted';
export const GET_SUBSCRIPTION_DATASET_ERROR = 'userSubscription/getSubscriptionDatasetError';

export const SET_STRIPE_ERROR = 'userSubscription/setStripeError';
export const RESET_SUBSCRPTION_ERROR = 'userSubscription/resetSubscriptionError';
export const RESET_STRIPE_REDIRECT_URL = 'userSubscription/resetStripeRedirectUrl';

export const getSubscriptionDataset = (payload = {}) => ({
  type: GET_SUBSCRIPTION_DATASET,
  payload,
});

export const getStripeConfig = (payload = {}) => ({
  type: GET_STRIPE_CONFIG,
  payload,
});

export const initiateUserSubscription = (payload = {}) => ({
  type: INITIATE_USER_SUBSCRIPTION,
  payload,
});

export const setStripeError = (payload = {}) => ({
  type: SET_STRIPE_ERROR,
  payload,
});

export const resetSubscriptionError = (payload = {}) => ({
  type: RESET_SUBSCRPTION_ERROR,
  payload,
});

export const resetStripeRedirectUrl = (payload = {}) => ({
  type: RESET_STRIPE_REDIRECT_URL,
  payload,
});
