import TextLoader from 'components/skeleton-loader/TextLoader';
import Frame from './Frame';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 80%;
`;

function SkeletonLoaders({ loadingTitle }) {
  return (
    <Frame>
      <Frame.Aside></Frame.Aside>
      <StyledDiv>
        <div className="mb-3 txt size-16 lh-28 gray-20">{loadingTitle}</div>
        <TextLoader width={'100%'} />
        <TextLoader width={'100%'} />
      </StyledDiv>
    </Frame>
  );
}

export default SkeletonLoaders;
