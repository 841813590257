import React, { useEffect } from 'react';
import Frame from './Frame';
import DropdownMenu from 'components/dropdowns/DropdownMenu';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from '../OrderPageContext';
import { Strings } from 'constants/Strings';

function DatasetSelect() {
  const { datasetList } = useSelector(({ userSubscription }) => userSubscription);
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const { ordersPageData, dispatchOrderPageAction, isReorder, orderTypePath } =
    useOrdersPagedata();
  const {
    chatRWD: { selectedDataSourceOption, errors },
  } = ordersPageData;
  const { data_source_object } = singleOrderData || {};

  useEffect(() => {
    if (data_source_object && data_source_object.name) {
      dispatchOrderPageAction({
        type: 'order/updateChatRWDField',
        payload: {
          field: 'selectedDataSourceOption',
          value: [
            {
              ...data_source_object,
              group: data_source_object?.hierarchy,
              label: data_source_object?.name || data_source_object?.scope,
              value: data_source_object?.name || data_source_object?.scope,
              isChecked: true,
            },
          ],
        },
      });
    }
  }, [isReorder, data_source_object]);

  const addLabelAndValueToDataSources = (data) => {
    return data?.map((entry) => {
      const updatedDataSources = entry.data_sources.map((dataSource) => ({
        ...dataSource,
        label: dataSource.name,
        value: dataSource.name,
      }));

      return {
        ...entry,
        data_sources: updatedDataSources,
      };
    });
  };

  const handleDataSourceOptionChange = (e, option, group) => {
    const { name, checked } = e.target;
    const groupExists = selectedDataSourceOption?.some(
      (opt) => opt.group === group && opt.label === name,
    );

    let updatedOptions;
    if (groupExists) {
      updatedOptions = selectedDataSourceOption?.map((opt) =>
        opt.group === group && opt.label === name ? { ...opt, isChecked: checked } : opt,
      );
    } else {
      updatedOptions = [
        ...selectedDataSourceOption,
        {
          group,
          label: name,
          isChecked: checked,
          ...option,
        },
      ];
    }
    const allUnchecked = updatedOptions.every((opt) => !opt.isChecked);
    if (allUnchecked) {
      dispatchOrderPageAction({
        type: 'order/setChatRWDErrorByKey',
        payload: {
          key: 'dataSourceSelectionError',
          error: Strings.dataSourceSelectionError,
        },
      });
    } else {
      dispatchOrderPageAction({
        type: 'order/setChatRWDErrorByKey',
        payload: {
          key: 'dataSourceSelectionError',
          error: '',
        },
      });
    }

    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'selectedDataSourceOption',
        value: updatedOptions,
      },
    });
  };

  return (
    <>
      <Frame>
        <Frame.Aside></Frame.Aside>
        <div className="w-100">
          <div className="mb-3 txt size-16 gray-20 lh-28 intro-text">
            Select your dataset:
          </div>
          <DropdownMenu
            type="group"
            groupKey="data_sources"
            options={addLabelAndValueToDataSources(datasetList)}
            onGroupCheckboxCheck={handleDataSourceOptionChange}
            selectedOptions={selectedDataSourceOption}
            placeholder="Select Answer"
            disabled={
              singleOrderData?.guid &&
              singleOrderData?.status === 'Pending' &&
              orderTypePath === 'edit'
            }
            isNoValidate={errors?.dataSourceSelectionError ? true : false}
            noScroll={true}
            showTooltipInfo={true}
          />

          {errors.dataSourceSelectionError && (
            <div className="color-error error-text">
              {errors.dataSourceSelectionError}
            </div>
          )}
        </div>
      </Frame>
    </>
  );
}

export default DatasetSelect;
