import React from 'react';
import styles from './chatrwd.module.scss';
import { useOrdersPagedata } from '../OrderPageContext';
import TextArea from 'components/form-input/TextArea';
import { ButtonCustom } from 'components/form-input/Button';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';

function ClinicalQuestionForm() {
  const {
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
    showButtonIcons,
    handleButtonAction,
  } = useOrdersPagedata();
  const { questionTitle } = chatRWD;

  function onClickOfbutton(action) {
    const option = { key: action };
    handleButtonAction(option);
  }

  function handleChange(e) {
    const { value } = e.target;
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'questionTitle',
        value: value,
      },
    });
    dispatchOrderPageAction({
      type: 'order/updatePICOOrderDetailsField',
      payload: {
        field: 'clinicalQuestion',
        value: value,
      },
    });
  }

  return (
    <div className={styles.aiWorflowContainer}>
      <>
        <TextArea
          placeholder="Enter your question here  (example: Is there a difference in skeletal-related events in female oncology 
	patients without a history of osteoporosis who received zoledronic acid vs denosumab?) and press return."
          value={questionTitle}
          rows={3}
          onChange={handleChange}
          id="textarea-clinical-question"
        />
        <div className="px-md-6 px-3 py-md-2 py-3 txt size-12 gray-10">
          This feature uses ChatGPT from OpenAI. Our goal is to make AI systems more
          natural and safe to interact with. Your feedback will help us improve.
        </div>

        <div className="flex-wrap flex-justify-end px-md-2 px-2 d-md-flex">
          <ButtonCustom
            cssClass={`blue-btn my-2 my-md-3 mx-md-1 mx-0 ${
              !questionTitle ? 'btn-disabled' : ''
            } ${styles.aiButton}`}
            disabled={!questionTitle}
            buttonAction="Generate PICO button clicked"
            id="bt-generate-PICO-button"
            onClick={() => onClickOfbutton(aiButtonsEnum.GENERATE_PICO)}
          >
            <div className="icon-button d-flex gap-7 flex-justify-center flex-items-center position-relative">
              {showButtonIcons(aiButtonsEnum.GENERATE_PICO)}
              <span>Generate PICO</span>
            </div>
          </ButtonCustom>

          <ButtonCustom
            cssClass={`blue-btn my-2 my-md-3 mx-md-1 mx-0 ${styles.aiButton}`}
            buttonAction="Mannually enter PICO button clicked"
            id="bt-manually-enter-PICO-button"
            onClick={() => onClickOfbutton(aiButtonsEnum.MANUALLY_ENTER_PICO)}
          >
            <div className="icon-button d-flex gap-7 flex-justify-center flex-items-center position-relative">
              {showButtonIcons(aiButtonsEnum.MANUALLY_ENTER_PICO)}
              <span>Manually Enter PICO</span>
            </div>
          </ButtonCustom>
        </div>
      </>
    </div>
  );
}

export default ClinicalQuestionForm;
