import React, { useEffect } from 'react';
import Frame from './Frame';
import DropdownMenu from 'components/dropdowns/DropdownMenu';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';
import { useOrdersPagedata } from '../OrderPageContext';
import { Strings } from 'constants/Strings';

function MotivationSelect() {
  const { motivations, singleOrderData } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const { ordersPageData, dispatchOrderPageAction } = useOrdersPagedata();
  const {
    chatRWD: { selectedProviderMotivation, errors },
  } = ordersPageData;

  const { motivations: ordersMotivations, patient } = singleOrderData || {};

  useEffect(() => {
    if (ordersMotivations && ordersMotivations.length) {
      const presentMotivations = ordersMotivations.find((motivation) => motivation.value);
      if (presentMotivations) {
        dispatchOrderPageAction({
          type: 'order/updateChatRWDField',
          payload: {
            field: 'selectedProviderMotivation',
            value: {
              label: presentMotivations?.category,
              value: presentMotivations?.category,
            },
          },
        });
      }
    }
  }, [ordersMotivations, patient]);

  function renderMotivationOption() {
    return (
      motivations &&
      motivations.length > 0 &&
      motivations
        ?.filter((motivation) => motivation.category.toLowerCase() !== labels.patientCare)
        .map((motivation) => ({
          label: motivation.category,
          value: motivation.category,
        }))
    );
  }

  const handleClickonOption = (option) => {
    if (errors?.motivation) {
      dispatchOrderPageAction({
        type: 'order/setChatRWDErrorByKey',
        payload: {
          key: 'motivation',
          error: '',
        },
      });
    }

    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'selectedProviderMotivation',
        value: option,
      },
    });
  };

  const handleMotivationClear = (e) => {
    e.stopPropagation();
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'selectedProviderMotivation',
        value: {},
      },
    });

    dispatchOrderPageAction({
      type: 'order/setChatRWDErrorByKey',
      payload: {
        key: 'motivation',
        error: Strings.motivationError,
      },
    });
  };

  return (
    <>
      <Frame>
        <Frame.Aside></Frame.Aside>
        <div className="w-100">
          <div className="txt size-16 gray-20 mb-3 lh-28 intro-text">
            Select your motivation:
          </div>
          <DropdownMenu
            options={renderMotivationOption()}
            onClickonOption={handleClickonOption}
            selectedOptions={selectedProviderMotivation}
            isClearable={selectedProviderMotivation?.label ? true : false}
            placeholder="Select Answer"
            onClearSelectedOption={handleMotivationClear}
            isNoValidate={errors?.motivation ? true : false}
          />
          {errors.motivation && (
            <div className="color-error error-text">{errors.motivation}</div>
          )}
        </div>
      </Frame>
      <Frame.FrameFooter></Frame.FrameFooter>
    </>
  );
}

export default MotivationSelect;
