import React from 'react';
import Table from 'components/Table';

const populationTableHeaders = [
  'Clinical Concept',
  'Phenotype Name',
  'Source',
  'Tags',
  '# of Rows',
];

const interventionTableHeaders = [
  'Clinical Concept',
  'Phenotype Name',
  'Source',
  'Tags',
  'Subset',
  '# of Rows',
];

const controlTableHeaders = [
  'Clinical Concept',
  'Phenotype Name',
  'Source',
  'Tags',
  'Subset',
  '# of Rows',
];

const outcomeTableHeaders = [
  'Clinical Concept',
  'Phenotype Name',
  'Source',
  'Tags',
  'Outcome Type',
  '# of Rows',
];

const otherTableHeaders = ['Clinical Concept', 'Phenotype Name', 'Source', '# of Rows'];

function getTableHeaders(type) {
  switch (type) {
    case 'population':
      return populationTableHeaders;
    case 'intervention':
      return interventionTableHeaders;
    case 'control':
      return controlTableHeaders;
    case 'outcome':
    case 'outcomes':
      return outcomeTableHeaders;
    default:
      return otherTableHeaders;
  }
}

function TableHeader({ phenotype }) {
  const headers = getTableHeaders(phenotype);

  return headers.map((header, index) => (
    <Table.TableHeader key={index}>{header}</Table.TableHeader>
  ));
}

export default TableHeader;
