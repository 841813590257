import React, { useState } from 'react';
import Table from 'components/Table';
import {
  AiOutlineMinusCircle,
  AiOutlinePlusSquare,
  AiOutlineRightCircle,
} from 'react-icons/ai';
import Codeset from './Codeset';
import styles from './phenotype.module.scss';
import OutcomePeriod from '../studyparameters/OutcomePeriod';
import { Overlay } from 'components/overlaypanel';
import Tag from 'components/tag/Tag';
import { useSelector } from 'react-redux';
import { labels } from 'constants/labels';

function OutcomephenotypeTable({
  entity,
  phenotype,
  onDelete,
  curEntity,
  entityRowIndex,
}) {
  const [expandRow, setExpandRow] = useState(false);
  const {
    phenotypeResponse: { phenotypes },
  } = useSelector(({ orderDetails }) => orderDetails);
  const colspan = phenotype === 'population' ? 5 : 6;
  // const phenotypeName = entity?.[0]?.code_set?.[0]?.concept_name || '-';
  const phenotypeName = curEntity?.phenotypes[0]?.entity_result ?? '';
  const isTagsPresent = curEntity?.tags?.length;

  const isError = entity?.some(({ code_set }) =>
    code_set?.some(
      ({ concept_description }) =>
        concept_description === labels.unableToReturnPhenotypeDescription,
    ),
  );

  function handleRenderContent(tags) {
    return (
      <div className="d-flex flex-column gap-7">
        <div className="txt size-12">Tags</div>
        <div className="d-flex flex-wrap gap-7">
          {tags.map((tag, index) => (
            <Tag key={`outcomes-tag-${tag}-${index}`} tagName={tag} />
          ))}
        </div>
      </div>
    );
  }

  return entity && entity.length
    ? entity.map((entityPhenotype, index) => (
        <React.Fragment
          key={`outcome-entity-${curEntity?.entity_name.split(' ').join('-')}-${index}`}
        >
          <Table.Row className={`${expandRow ? styles.activeRow : ''}`}>
            <Table.TableData>
              {!isError ? (
                <div
                  className="d-flex gap-7 cursor-pointer align-items-center"
                  onClick={() => setExpandRow((expand) => !expand)}
                >
                  {expandRow ? (
                    <AiOutlineMinusCircle className="flex-shrink-0" size={20} />
                  ) : (
                    <AiOutlineRightCircle className="flex-shrink-0" size={20} />
                  )}
                  {curEntity?.entity_name}
                </div>
              ) : (
                curEntity?.entity_name
              )}
            </Table.TableData>
            <Table.TableData>
              {!isError ? (
                phenotypeName
              ) : (
                <span className="color-error">{labels.failedToGeneratePhenotype}</span>
              )}
            </Table.TableData>
            <Table.TableData>{!isError && entityPhenotype.source}</Table.TableData>
            <Table.TableData>
              {isTagsPresent ? (
                <Overlay title={<AiOutlinePlusSquare size={18} />}>
                  {handleRenderContent(curEntity?.tags)}
                </Overlay>
              ) : (
                ''
              )}
            </Table.TableData>
            <Table.TableData>
              {!isError && curEntity.outcome_type ? curEntity.outcome_type : '-'}
            </Table.TableData>
            <Table.TableData className="text-center">
              {!isError && entityPhenotype.code_set?.length}
            </Table.TableData>
          </Table.Row>

          {expandRow && (
            <Codeset
              colspan={colspan}
              codeset={entityPhenotype.code_set}
              onDelete={onDelete}
            >
              <Table.Row>
                <Table.TableData colSpan={colspan}>
                  <div className="bg-white px-3">
                    <OutcomePeriod
                      entity={curEntity}
                      entityRowIndex={entityRowIndex}
                      phenotype={phenotype}
                    />
                  </div>
                </Table.TableData>
              </Table.Row>
            </Codeset>
          )}
        </React.Fragment>
      ))
    : null;
}

export default OutcomephenotypeTable;
