import React from 'react';

function StudyDetailsSummary({ studyFormatObject, children }) {
  return (
    <div className="w-80">
      {studyFormatObject?.reason !== '' && studyFormatObject?.reason !== undefined ? (
        <div className="mb-3 txt size-16 gray-20 lh-28 intro-text">
          {studyFormatObject?.reason}
        </div>
      ) : (
        <>
          {studyFormatObject?.display_text_1 && (
            <div
              className="mb-3 txt size-16 gray-20 lh-28 intro-text"
              dangerouslySetInnerHTML={{
                __html: studyFormatObject?.display_text_1,
              }}
            />
          )}
        </>
      )}

      {children}

      {studyFormatObject?.display_text_2 && (
        <div className="mt-3 txt size-16 gray-20 lh-28 conclusion-text">
          {studyFormatObject.display_text_2}
        </div>
      )}
    </div>
  );
}

export default StudyDetailsSummary;
