import React, { useEffect, useState } from 'react';
import Frame from './Frame';
import { TextInput } from 'components/form-input';
import styles from './chatrwd.module.scss';
import { ButtonCustom } from 'components/form-input/Button';
import { useOrdersPagedata } from '../OrderPageContext';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { Strings } from 'constants/Strings';
import StudyDetailsSummary from './StudyDetailsSummary';

const allKeysHaveValues = (obj) => {
  return Object.values(obj).every(
    (value) => value !== undefined && value !== null && value !== '',
  );
};

function StudyForm({ studyFormat = {}, onFormSubmit, onCancel, data = {} }) {
  const {
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
  } = useOrdersPagedata();

  const [error, setError] = useState('');

  useEffect(() => {
    if (!studyFormat) return;

    const formData = {};
    for (const key in studyFormat) {
      formData[key] = chatRWD?.formValues[key]
        ? chatRWD?.formValues[key]
        : studyFormat[key];
    }

    dispatchOrderPageAction({
      type: 'order/updateChatRWDFormValues',
      payload: formData,
    });
  }, []);

  function handleChange(value, key) {
    if (error) setError('');
    dispatchOrderPageAction({
      type: 'order/updateChatRWDFormValuesByField',
      payload: {
        key,
        value,
      },
    });
  }

  function handleButtonClick(key) {
    switch (key) {
      case aiButtonsEnum.SAVE_PICO:
        if (!allKeysHaveValues(chatRWD?.formValues)) {
          setError(Strings.allFieldsMandatoryIndicator);
          return;
        }
        onFormSubmit(key);
        break;
      case aiButtonsEnum.CANCEL_PICO:
        dispatchOrderPageAction({
          type: 'order/clearChatRWDFormValues',
        });
        onCancel();
        break;
      default:
        console.log('wrong option');
    }
  }

  if (studyFormat && Object.keys(studyFormat).length > 0) {
    return (
      <>
        <form>
          <Frame dataTestid="manually-enter-pico-frame">
            <Frame.Aside></Frame.Aside>
            <div className="w-100">
              <StudyDetailsSummary studyFormatObject={data}>
                <div className="w-100">
                  <div className={styles.studyFormatFormContainer}>
                    {Object.entries(studyFormat).map(([key]) => (
                      <React.Fragment key={key}>
                        <div className="mb-1 study-details-form-group">
                          <strong className="text-capitalize">{key}: </strong>
                          <TextInput
                            value={chatRWD?.formValues[key]}
                            onChange={(e) => handleChange(e.target.value, key)}
                            isFloatingLabel={false}
                            id={`textInput-ai-${key}`}
                            ariaLabel={key}
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  </div>

                  {error && <div className="color-error error-text mt-2">{error}</div>}
                </div>
              </StudyDetailsSummary>
            </div>
          </Frame>
          <Frame.FrameFooter>
            <ButtonCustom
              cssClass={`blue-btn ${styles.aiButton}`}
              buttonAction="Save PICO button clicked"
              id="bt-save-PICO-button"
              onClick={() => handleButtonClick(aiButtonsEnum.SAVE_PICO)}
              type="submit"
            >
              <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                <span>Save</span>
              </div>
            </ButtonCustom>
            <ButtonCustom
              cssClass={`blue-btn ${styles.aiButton}`}
              buttonAction="Cancel PICO button clicked"
              id="bt-cancel-PICO-button"
              onClick={() => handleButtonClick(aiButtonsEnum.CANCEL_PICO)}
            >
              <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                <span>Cancel</span>
              </div>
            </ButtonCustom>
          </Frame.FrameFooter>
        </form>
      </>
    );
  }

  return null;
}

export default StudyForm;
