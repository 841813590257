import React, { useRef, useEffect } from 'react';
import styles from './dropdown.module.scss';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';

function DropdownContainer({
  children,
  isOpen = false,
  selectedOption = {},
  placeholder = '',
  isClearable = false,
  onClearSelectedOption = () => {},
  clearOptinIcon = (
    <AiOutlineClose
      onClick={onClearSelectedOption}
      className="txt size-15 cursor-pointer"
    />
  ),
  onCloseDropDown = () => {},
  onInputControlClick = () => {},
  indicatorIcon = <HiOutlineChevronDown className="txt size-15" />,
  disabled = false,
  isNoValidate = false,
  noScroll = false,
  height = '45px',
  dropdownType = 'standard',
  readOnly = true,
}) {
  const dropdownRef = useRef();

  let value = '';
  if (dropdownType === 'group') {
    value =
      selectedOption
        ?.filter((item) => item.isChecked)
        .map((item) => item.label)
        .join(', ') || '';
  } else if (dropdownType === 'multiselect') {
    value =
      Object.keys(selectedOption).length > 0
        ? Object.keys(selectedOption)
            .filter((key) => selectedOption[key])
            .join(', ')
        : '';
  } else {
    value = selectedOption?.label || '';
  }

  useEffect(() => {
    function handler(event) {
      if (dropdownRef && !dropdownRef.current?.contains(event.target)) {
        onCloseDropDown();
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [isOpen]);

  return (
    <div
      className={`${styles.dropdownMenuContainer} ${disabled ? 'disabled' : ''}`}
      ref={dropdownRef}
    >
      <div className="position-relative">
        <input
          className={`${styles.inputControl} ${
            isNoValidate && styles.inputnovalidate
          } txt size-13 font-inter w-500`}
          value={value}
          placeholder={placeholder}
          onClick={onInputControlClick}
          style={{ minHeight: height }}
          readOnly={readOnly}
        />

        {/* <div
        className={`${styles.inputControl} ${isNoValidate && styles.inputnovalidate}`}
        onClick={onInputControlClick}
        style={{ minHeight: height }}
      >
      <div className={styles.inputContainer}>
          <span className="txt size-14 font-inter w-500">{value || placeholder}</span>
        </div> */}
        <div className={`d-flex align-items-center gap-7 ${styles.inputIconsContainer}`}>
          {isClearable && clearOptinIcon}
          {indicatorIcon}
        </div>
      </div>

      {isOpen && !disabled && (
        <div className={styles.dropdownMenu} id="dropdown-panel">
          <div className={`${styles.dropdownMenuList} ${noScroll && styles.noScroll}`}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownContainer;
