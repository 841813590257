import React, { useState, useRef, useEffect } from 'react';
import RangeSlider from './form-input/RangeSlider';
import Checkbox from './form-input/Checkbox';
import CustomDropdown from './form-input/CustomDropdown';
import { TextInput } from './form-input';
import RadioButton from './form-input/RadioButton';
import { Strings } from 'constants/Strings';
import DropdownMenu from './dropdowns/DropdownMenu';

const outcomePeriodOptions = [
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Months',
    value: 'months',
  },
  {
    label: 'Years',
    value: 'years',
  },
];

const GeneralStudyParametersForm = ({ setGeneralStudyFormParams }) => {
  const todaysDate = new Date();
  let initialState = {
    studyYears: {
      minValue: 2015,
      maxValue: todaysDate.getFullYear(),
      min: 2010,
      max: 2024,
    },
    ageRange: {
      minValue: 18,
      maxValue: 90,
      min: 0,
      max: 100,
    },
    followUpPeriod: {
      minValue: 3,
      maxValue: 180,
      min: 0,
      max: 365,
    },
  };

  const genderInitialState = ['Female', 'Male'];
  // const raceInitialState = ['White', 'Black', 'Asian', 'Other'];
  // const ethnicityInitialState = ['Hispanic', 'Not Hispanic', 'Unknown'];

  const genderValue = 'Female and Male';
  const raceValue = 'White, Black, Asian and Other';
  const ethnicityValue = 'Hispanic, Not Hispanic, Unknown';

  const [rangeValues, setRangeValues] = useState(initialState);
  const [gender, setGender] = useState(genderInitialState);
  // const [race, setRace] = useState(raceInitialState);
  // const [ethnicity, setEthnicity] = useState(ethnicityInitialState);
  const genderList = ['Female', 'Male'];
  const raceList = ['White', 'Black', 'Asian', 'Other'];
  const ethnicityList = ['Hispanic', 'Not Hispanic', 'Unknown'];
  const [selectedValue, setSelectedValue] = useState({
    min: outcomePeriodOptions[0],
    max: outcomePeriodOptions[2],
  });
  const [outcomeTimePeriod, setOutcomeTimePeriod] = useState({
    start: 3,
    end: 1,
  });
  const [downsampleOption, setDownsampleOption] = useState('downsample');
  const [totalPatients, setTotalPatients] = useState('100,000');

  const handleInput = (e, name) => {
    if (
      rangeValues[name].minValue !== e.minValue ||
      rangeValues[name].maxValue !== e.maxValue
    ) {
      setRangeValues((rangeValues) => ({
        ...rangeValues,
        [name]: { ...rangeValues[name], minValue: e.minValue, maxValue: e.maxValue },
      }));
    }
  };

  const handleSelect = (type, option) => {
    setSelectedValue((prevState) => ({
      ...prevState,
      [type === 'min' ? 'min' : 'max']: option,
    }));
  };

  useEffect(() => {
    setGeneralStudyFormParams({
      study_years_min: rangeValues.studyYears.minValue,
      study_years_max: rangeValues.studyYears.maxValue,
      age_range_min: rangeValues.ageRange.minValue,
      age_range_max: rangeValues.ageRange.maxValue,
      sex: gender,
      // race: race,
      // ethnicity: ethnicity,
      follow_up_min_value: outcomeTimePeriod.start,
      follow_up_min_type: selectedValue?.min?.value,
      follow_up_max_value: outcomeTimePeriod.end,
      follow_up_max_type: selectedValue?.max?.value,
      downsample: downsampleOption === 'downsample' ? true : false,
      downsample_number:
        downsampleOption === 'downsample'
          ? Number(totalPatients.split(',').join(''))
          : null,
    });
  }, [
    gender,
    rangeValues,
    selectedValue.min,
    selectedValue.max,
    outcomeTimePeriod.start,
    outcomeTimePeriod.end,
    downsampleOption,
    totalPatients,
  ]);

  const handleOptionChange = (e) => {
    setDownsampleOption(e.target.value);
  };

  const handleTotalPatientsChange = (event) => {
    let { value } = event.target;
    // Remove non-numeric characters except for dot and minus sign
    value = value.replace(/[^\d.-]/g, '');
    // Format the number with commas
    const formattedNumber = numberFormatter(value);
    setTotalPatients(formattedNumber);
  };

  const numberFormatter = (number) => {
    const parts = number.split('.');
    // Add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  const handleCheckboxChange = (e, name) => {
    var values = '';
    var data = '';
    if (name === 'sex') {
      data = genderList;
      if (e.target.value === genderValue && e.target.checked) {
        values = [genderValue];
      } else {
        if (gender.includes(e.target.value)) {
          values = gender.filter((item) => item !== e.target.value);
        } else {
          values = [...gender, e.target.value];
        }
      }
    } else if (name === 'race') {
      data = raceList;
      if (e.target.value === raceValue && e.target.checked) {
        values = [raceValue];
      } else {
        if (race.includes(e.target.value)) {
          values = race.filter((item) => item !== e.target.value);
        } else {
          values = [...race, e.target.value];
        }
      }
    } else {
      data = ethnicityList;
      if (e.target.value === ethnicityValue && e.target.checked) {
        values = [ethnicityValue];
      } else {
        if (ethnicity.includes(e.target.value)) {
          values = ethnicity.filter((item) => item !== e.target.value);
        } else {
          values = [...ethnicity, e.target.value];
        }
      }
    }
    // data?.map((filter) => {
    //   if (e.target.value === filter) {
    //     if (e.target.checked) {
    //       values.push(e.target.value);
    //     } else {
    //       var index = values.indexOf(e.target.value);
    //       if (index > -1) {
    //         values.splice(index, 1);
    //       }
    //     }
    //   }
    // });
    if (name === 'sex') {
      if (e.target.value === genderValue && values.length === 0) {
        setGender(genderInitialState);
      } else {
        setGender(values);
      }
    } else if (name === 'race') {
      if (e.target.value === raceValue && values.length === 0) {
        setRace(raceInitialState);
      } else {
        setRace(values);
      }
    } else {
      if (e.target.value === ethnicityValue && values.length === 0) {
        setEthnicity(ethnicityInitialState);
      } else {
        setEthnicity(values);
      }
    }
  };
  const setCheckboxChecked = (value, name) => {
    if (name === 'sex') {
      return checkFilterValue(value, gender);
    } else if (name === 'race') {
      return checkFilterValue(value, race);
    } else {
      return checkFilterValue(value, ethnicity);
    }
  };

  const checkFilterValue = (value, newFilterValue) => {
    if (newFilterValue) {
      var isPresent = newFilterValue.includes(value);
      return isPresent;
    } else {
      return false;
    }
  };

  const hadleCheckboxDisable = (value, name) => {
    if (name === 'sex' && value !== genderValue && gender.includes(genderValue)) {
      return true;
    } else if (name === 'race' && value !== raceValue && race.includes(raceValue)) {
      return true;
    } else if (
      name === 'ethnicity' &&
      value !== ethnicityValue &&
      ethnicity.includes(ethnicityValue)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showCheckoxes = (list, name) => {
    const listItems = list?.map((value, index) => (
      <li
        key={index + '-' + value}
        className={`${value === 0 ? ' disabled-filter' : ''}`}
      >
        <Checkbox
          buttonName={value}
          onChange={(e) => handleCheckboxChange(e, name)}
          name={name}
          value={value}
          checked={setCheckboxChecked(value, name)}
          id={`checkbox-mobile-filter${name}-${value}`}
          disabled={hadleCheckboxDisable(value, name)}
        />
      </li>
    ));
    return <ul className={''}>{listItems}</ul>;
  };

  return (
    <div className="green-border px-md-6 px-3 py-md-4 py-3 parameter-form">
      <div className="parameter-form-heading">General Study Parameters</div>
      <div className="input-heading-label mt-4">
        Study Years - please select the MIN and MAX years for your study
      </div>
      <RangeSlider
        min={rangeValues.studyYears.min}
        max={rangeValues.studyYears.max}
        minValue={rangeValues.studyYears.minValue}
        maxValue={rangeValues.studyYears.maxValue}
        name={'studyYears'}
        handleInputChange={handleInput}
        step={1}
      />
      <div className="input-heading-label mt-5">
        Age Range - please select the MIN and MAX ages for your study
      </div>
      <RangeSlider
        min={rangeValues.ageRange.min}
        max={rangeValues.ageRange.max}
        minValue={rangeValues.ageRange.minValue}
        maxValue={rangeValues.ageRange.maxValue}
        name={'ageRange'}
        handleInputChange={handleInput}
        step={1}
      />
      <div className="input-heading-label mt-5">
        Sex - please select the sexes to include
      </div>
      {showCheckoxes(genderList, 'sex')}
      {/* <div className="input-heading-label mt-3">
        Race - please select the races to include
      </div>
      {showCheckoxes(raceList, 'race')}
      <div className="input-heading-label mt-3">
        Ethnicity - please select the ethnicities to include
      </div>
      {showCheckoxes(ethnicityList, 'ethnicity')} */}
      {/* <div className="input-heading-label mt-3">
        Follow Up Period for Outcome - please select the days after index date to include{' '}
      </div>
      <div className='mb-4'>
        <RangeSlider
          min={rangeValues.followUpPeriod.min}
          max={rangeValues.followUpPeriod.max}
          minValue={rangeValues.followUpPeriod.minValue}
          maxValue={rangeValues.followUpPeriod.maxValue}
          name={'followUpPeriod'}
          handleInputChange={handleInput}
          step={3}
        />
      </div> */}
      <div className="d-flex flex-column gap-15" id="custom-textinput-container">
        <div className="d-flex flex-column gap-10">
          <div className="txt size-16 w-600 lh-28 form-input-header">
            Outcome time period
          </div>
          <div className="custom-textinput-container input-wrapper">
            <div className="d-xl-flex gap-50">
              <div>
                <label
                  className="mb-1 txt size-14 w-500 lh-20 font-inter primary-1"
                  htmlFor="start"
                >
                  Start
                </label>
                <div className="d-lg-flex gap-20">
                  <TextInput
                    cssClass="input-layout-1 input-1 mr-0 txt font-inter primary-1 size-14 w-500 mb-1"
                    type="number"
                    value={outcomeTimePeriod.start}
                    onChange={(e) =>
                      setOutcomeTimePeriod((prev) => ({
                        ...prev,
                        start: Number(e.target.value),
                      }))
                    }
                  />

                  <div className="input-conatainer">
                    {/* <CustomDropdown
                      options={outcomePeriodOptions}
                      onSelect={(option) => handleSelect('min', option)}
                      selectedOption={selectedValue?.min?.value}
                      selectedOptionIcon={<div className="img size-16 check-mark"></div>}
                      selectedOptionStyleClass="selected"
                      placeholder="Select"
                      placeholderImg={<div className="img size-16 up-down-arrow"></div>}
                    /> */}
                    <div className="input-2">
                      <DropdownMenu
                        options={outcomePeriodOptions}
                        selectedOptions={selectedValue?.min}
                        onClickonOption={(option) => handleSelect('min', option)}
                        placeholder="Select"
                        height="35px"
                        optionsPadding="small"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label
                  className="mb-1 txt size-14 w-500 lh-20 font-inter primary-1"
                  htmlFor="start"
                >
                  End
                </label>
                <div className="d-lg-flex gap-20">
                  <TextInput
                    cssClass="input-layout-1 input-1 mr-0 txt font-inter primary-1 size-14 w-500 mb-1"
                    type="number"
                    value={outcomeTimePeriod.end}
                    onChange={(e) =>
                      setOutcomeTimePeriod((prev) => ({
                        ...prev,
                        end: Number(e.target.value),
                      }))
                    }
                  />
                  <div className="input-conatainer">
                    {/* <CustomDropdown
                      options={outcomePeriodOptions}
                      onSelect={(option) => handleSelect('max', option)}
                      selectedOption={selectedValue?.max?.value}
                      selectedOptionIcon={<div className="img size-16 check-mark"></div>}
                      selectedOptionStyleClass="selected"
                      placeholder="Select"
                      placeholderImg={<div className="img size-16 up-down-arrow"></div>}
                    /> */}
                    <div className="input-2">
                      <DropdownMenu
                        options={outcomePeriodOptions}
                        selectedOptions={selectedValue?.max}
                        onClickonOption={(option) => handleSelect('max', option)}
                        placeholder="Select"
                        height="35px"
                        optionsPadding="small"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {outcomeTimePeriod.end === 0 && (
              <div className="color-error error-text">{Strings.outcomePeriodError}</div>
            )}
          </div>
        </div>

        <div className="d-flex flex-column gap-10">
          <div>
            <div className="txt size-16 w-600 lh-28 form-input-header mb-1">
              Speed up analysis by downsampling?
            </div>
            <div className="txt size-10 lh-20 font-inter w-500">
              Limiting your study to a random sample can speed up your query by 50% or
              more for large cohorts
            </div>
          </div>
          <div className="input-wrapper">
            <div className="d-xl-flex gap-50 align-items-center">
              <div className="d-lg-flex gap-15 align-items-center mb-1 radio-input-wrapper">
                <RadioButton
                  buttonName="Downsample"
                  onClick={handleOptionChange}
                  name="downsample"
                  checked={downsampleOption === 'downsample'}
                  id="downsample-radio-button"
                  value="downsample"
                />
                <div className="d-flex gap-10 align-items-center">
                  <TextInput
                    cssClass={`input-layout-1 text-input-2 mr-0 txt font-inter primary-1 size-14 w-500 ${
                      downsampleOption === 'fullDataset' && 'disabled'
                    }`}
                    type="text"
                    value={totalPatients}
                    onChange={handleTotalPatientsChange}
                    disabled={downsampleOption === 'fullDataset'}
                  />
                  <div className="txt size-12 w-500 lh-20 font-inter">Total Patients</div>
                </div>
              </div>
              <div className="mb-1 radio-input-wrapper">
                <RadioButton
                  buttonName="Full dataset"
                  onClick={handleOptionChange}
                  name="downsample"
                  checked={downsampleOption === 'fullDataset'}
                  id="full-dataset-radio-button"
                  value="fullDataset"
                />
              </div>
            </div>
            {downsampleOption === 'downsample' &&
              (Number(totalPatients.split(',').join('')) < 100 ||
                Number(totalPatients.split(',').join('')) > 10000000) && (
                <div className="color-error error-text">{Strings.downsampleError}</div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralStudyParametersForm;
