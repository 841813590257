import React, { useState, useRef, useEffect } from "react";

function CustomDropdown({ 
  options, 
  onSelect, 
  selectedOption,
  selectedOptionIcon,
  selectedOptionStyleClass,
  placeholder,
  placeholderImg,
  dropdownContainerClass = "custom-dropdown input-layout-1 input-2 cursor-pointer position-relative",
  lisStyleClass = "txt size-14 lh-20 w-500 py-1 options-wrapper mb-1 font-inter primary-1",
  placeholderStyleClass = "txt size-14 w-500 font-inter"
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectOption = (option) => {
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <>
      <div ref={dropdownRef} 
        className={dropdownContainerClass} 
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={placeholderStyleClass}>{selectedOption ? selectedOption : placeholder}</div>
        {placeholderImg && <div className="dropdown-arrow-icon">{placeholderImg}</div>}
        {isOpen && (
          <ul className="custom-dropdown-panel">
            {options.map((option, index) => (
              <li
                className={`${lisStyleClass}
                ${selectedOptionIcon && option.value == selectedOption ? `${selectedOptionStyleClass} d-flex align-items-center justify-content-between` : ''}`}
                key={index}
                onClick={() => handleSelectOption(option)}>
                {option.value}
                {selectedOptionIcon && option.value === selectedOption && selectedOptionIcon}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default CustomDropdown;