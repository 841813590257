import React from 'react';
import PICphenotypeTable from './PICphenotypeTable';
import Table from 'components/Table';
import TableHeader from './TableHeader';
import AgeRange from '../studyparameters/AgeRange';
import styles from './phenotype.module.scss';

function PICphenotypelayout({ data, phenotype, onDelete }) {
  const entities = data[phenotype]?.entities || [];

  return (
    <div>
      <div className={styles.tableResponsive}>
        <Table>
          <Table.TableHead>
            <Table.Row>
              {entities && entities.length > 0 && <TableHeader phenotype={phenotype} />}
            </Table.Row>
          </Table.TableHead>
          <Table.TableBody>
            {entities &&
              entities.length > 0 &&
              entities.map((entity, index) => (
                <PICphenotypeTable
                  key={`pic-entity-${phenotype}-row-${index}`}
                  curEntity={entity}
                  entity={entity.phenotypes}
                  phenotype={phenotype}
                  onDelete={(codeSet) => onDelete({ ...codeSet, entityIndex: index })}
                />
              ))}
          </Table.TableBody>
        </Table>
      </div>
      <div className="bg-white px-3 py-5 mt-4">
        <AgeRange phenotype={phenotype} />
      </div>
    </div>
  );
}

export default PICphenotypelayout;
