import React from 'react';
import styles from './chatrwd.module.scss';
import atroposLogo from '../../../assets/images/atropos_fav_logo.png';

const defaultButtons = {
  TRY_AGAIN: 'Try Again',
  START_OVER: 'Start Over',
};

function Frame({ children, buttons = defaultButtons, dataTestid = 'frame' }) {
  return (
    <>
      <div className={styles.frameContainer} data-testid={dataTestid}>
        <div
          className={`d-md-flex gap-30 green-bg px-md-6 px-3 py-md-4 py-3 ${styles.frameBody}`}
        >
          {children}
        </div>
      </div>
    </>
  );
}

function Aside() {
  return (
    <div className="details-frame-img row-flex flex-shrink-0">
      <img className={styles.framelogo} src={atroposLogo} alt="logo" />
    </div>
  );
}

function FrameFooter({ children, dataTestid = 'frame-footer' }) {
  return (
    <div
      className={`d-flex gap-7 flex-wrap flex-justify-end ${styles.frameFooter}`}
      data-testid={dataTestid}
    >
      {children}
    </div>
  );
}

Frame.FrameFooter = FrameFooter;
Frame.Aside = Aside;

export default Frame;
