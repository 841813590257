import React from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import styles from './tooltip.module.scss';

function Tooltip({ tooltipIcon, name = '', tooltiptext, cssClassName, children }) {
  const mixpanel = useMixpanel();
  return (
    <div
      className={`${styles.tooltip} ${cssClassName} cursor-pointer`}
      onMouseEnter={() => {
        mixpanel.track(`${name} tooltip opened event`);
      }}
      onMouseOut={() => {
        mixpanel.track(`${name}  tooltip closed event`);
      }}
    >
      {tooltipIcon}
      <span className={styles.tooltiptext}>{children}</span>
    </div>
  );
}

export default Tooltip;
