import React from 'react';
import Checkbox from 'components/form-input/Checkbox';
import styles from './dropdown.module.scss';

function Multiselect({ options = [], onCheckboxCheck = () => {}, selectedOptions = {} }) {
  return options?.map((option, index) => (
    <li
      key={index + '-' + option.name}
      className={`${styles.dropdownOption} txt size-14 font-inter w-500 ${
        option.disabled ? 'disabled-filter' : ''
      }`}
    >
      <Checkbox
        buttonName={option.label}
        onChange={(e) => onCheckboxCheck(e, option)}
        name={option.name}
        value={option.value}
        checked={selectedOptions[option.name]}
        id={`checkbox-mobile-filter ${option}-${option.name}`}
        disabled={option.disabled ? true : false}
      />
    </li>
  ));
}

export default Multiselect;
