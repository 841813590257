import React from 'react';
import CustomModal from 'components/CustomModal';
import { Strings } from 'constants/Strings';
import { useLocation, useHistory } from 'react-router-dom';
import { useOrdersPagedata } from '../OrderPageContext';
import { ButtonCustom } from 'components/form-input/Button';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { useSelector } from 'react-redux';

function ConfirmNavigationModal() {
  const location = useLocation();
  let history = useHistory();
  const { orderScopeData } = useSelector(({ orderDetails }) => orderDetails);
  const {
    ordersPageData: { isConfirmationModalOpen },
    dispatchOrderPageAction,
  } = useOrdersPagedata();

  function handleModal(isOpen) {
    dispatchOrderPageAction({
      type: 'order/setNavigationConfirmationModalOpen',
      payload: isOpen,
    });
  }

  function handleOnCancelRequest() {
    if (location?.state?.isFromSearch) {
      history.push({
        pathname:
          orderScopeData?.scopeTabIndex === 1
            ? urlConstantsEnum.LIBRARY_SEARCH_RESULT
            : urlConstantsEnum.SEARCH_RESULT,
        state: {
          searchQuery: location?.state?.searchText,
        },
      });
    } else {
      history.push(
        orderScopeData?.scopeTabIndex === 1
          ? urlConstantsEnum.LIBRARY_DASHBOARD
          : urlConstantsEnum.DASHBOARD,
      );
    }
  }

  return (
    <CustomModal
      isOpen={isConfirmationModalOpen}
      cssClass="white-overlay"
      modalname="PHI modal"
      overlayCssClass={'phi-modal-overlay'}
      handleClose={() => {}}
    >
      <div className="email-modal p-5 delete-modal cancel-request-confirmation-modal">
        <div className="profile-sub-header mt-4 mb-2">
          {Strings.cancelOrderRequestModalTitle}
        </div>
        <div className="cancel-sub-header mt-4">
          {Strings.cancelOrderRequestModalDescription}
        </div>
        <div className="d-md-flex flex-justify-end">
          <ButtonCustom
            cssClass="plain-btn mt-5 px-4"
            onClick={() => handleModal(false)}
            buttonAction={'continue chatting button clicked'}
            actionLabel={'continue chatting'}
            id={'btn-continue-chatting-button'}
          >
            {Strings.continueChattingModalButton}
          </ButtonCustom>
          <ButtonCustom
            cssClass="btn-primary mt-2 mt-md-5 px-4 ml-md-3"
            onClick={() => handleOnCancelRequest()}
            buttonAction={'Cancel Request button clicked'}
            actionLabel={'Cancel Request'}
            id={'btn-cancel-request-button'}
          >
            {Strings.CancelRequestButton}
          </ButtonCustom>
        </div>
      </div>
    </CustomModal>
  );
}

export default ConfirmNavigationModal;
