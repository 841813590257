import React from 'react';
import Cookies from 'js-cookie';
import TextArea from 'components/form-input/TextArea';
import { INPUT_SIZE } from 'constants/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOrdersPagedata } from '../OrderPageContext';
import {
  modifyPrognostogramOrder,
  orderPrognostogram,
  clearOrderFormData,
  resetOrderIndexData,
} from 'redux/modules/orderDetails/actions';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import FormButtons from '../FormButtons';

function FreeTextOrder() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isUploadingDocument, singleOrderData } = useSelector(
    ({ orderDetails }) => orderDetails,
  );

  const {
    isFormValid,
    orderPayloadData,
    dispatchOrderPageAction,
    isReorder,
    orderTypePath,
    ordersPageData: { PICOOrderDetails },
    token,
  } = useOrdersPagedata();

  const { clinicalQuestion, initialClinicalQuestions } = PICOOrderDetails;

  const onInputChange = (e, name) => {
    if (e.target.value.length <= INPUT_SIZE) {
      const data = {
        [name]: e.target.value,
      };

      dispatchOrderPageAction({
        type: 'order/PICOOrderDetails',
        payload: data,
      });
    } else {
      e.preventDefault();
    }
  };

  const submitOrder = () => {
    if (isFormValid()) {
      dispatch(resetOrderIndexData());
      dispatchOrderPageAction({
        type: 'order/seIsOrderSubmitted',
        payload: true,
      });

      const order = {
        ...orderPayloadData,
        order: {
          ...orderPayloadData?.order,
          initial_clinical_questions: !initialClinicalQuestions
            ? clinicalQuestion
            : initialClinicalQuestions,
          clinical_questions: clinicalQuestion,
          question_title: clinicalQuestion,
          pico_guid: '',
          user_phenotypes: '',
          product_case_type: 'PG',
          population: '',
          intervention: '',
          control: '',
          outcome: '',
          timeframe: '',
        },
      };

      if (isReorder) {
        dispatch(
          modifyPrognostogramOrder({
            params: order,
            orderGuid: singleOrderData?.guid,
            token: token || '',
            isChatrwd: false,
            navigateToDashboard: navigateToDashboard,
          }),
        );
      } else {
        dispatch(
          orderPrognostogram({
            params: order,
            csrfToken: Cookies.get('csrftoken'),
            token: token || '',
            isChatrwd: false,
            navigateToDashboard: navigateToDashboard,
          }),
        );
      }
    }
  };

  const navigateToDashboard = () => {
    dispatchOrderPageAction({
      type: 'order/seIsOrderSubmitted',
      payload: false,
    });
    dispatch(clearOrderFormData());
    history.push(urlConstantsEnum.ORDER_SUBMITTED);
  };

  return (
    <div>
      <div className="study-protocol-text mb-3 mt-4">
        If you have a study protocol that you would like us to emulate, please include it
        using the Document Upload feature in the left bar.
      </div>
      <div>
        <TextArea
          placeholder="Write your clinical question here.."
          value={clinicalQuestion}
          rows={14}
          onChange={(e) => onInputChange(e, 'clinicalQuestion')}
          id={'textarea-clinical-question'}
        />
        <div className="text-count">
          {clinicalQuestion.length}/{INPUT_SIZE}
        </div>

        <FormButtons
          disabled={!clinicalQuestion || isUploadingDocument}
          onSubmit={submitOrder}
          buttonAction="Submit order button clicked, order type PG"
          actionLabel="New PG order is submitted"
          id="btn-submit-order-button"
        />
      </div>
    </div>
  );
}

export default FreeTextOrder;
