import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getorderByGuid,
  getMotivations,
  fetchOrderTaskStatus,
  clearOrderFormData,
} from 'redux/modules/orderDetails/actions';
import {
  requestGetUserProfile,
  getRequesters,
  getUsersConfig,
} from 'redux/modules/userProfile/actions';
import { useOrdersPagedata } from './OrderPageContext';
import { getSubscriptionDataset } from 'redux/modules/userSubscription/actions';
import { Sidebar } from './sidebar';
import OrderSwitcher from './orderswitcher/OrderSwitcher';
import { Header } from './header';
import AuthLoader from 'components/AuthLoader';
import ConfirmNavigationModal from './modals/ConfirmNavigationModal';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import ChatrwdOrderLoader from 'components/ChatrwdOrderLoader';
import Unauthorized from './modals/Unauthorized';
import ConfirmStartOverModal from './modals/ConfirmStartOverModal';

function OrderpageLayout() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  let intervalId = useRef(null);
  const { isgettingOrderDetails, isOrderLoading, orderScopeData, orderData } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const { userData } = useSelector(({ userProfile }) => userProfile);
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const {
    ordersPageData: { isOrderSubmitted },
    handleReset,
    dispatchOrderPageAction,
    token,
  } = useOrdersPagedata();

  useEffect(() => {
    return () => {
      handleReset();
      clearTaskInterval();
    };
  }, []);

  useEffect(() => {
      console.log("ORDER DATA: ", params)
    if (params && params?.guid && token) {
      getOrderDetails(params);
    }
  }, [params?.guid, token]);

  useEffect(() => {
    if (!userData && token) {
      getProfileData();
    }
  }, [userData, token]);

  useEffect(() => {
    if (userData?.guid && token) {
      getUserRequesters();
    }
  }, [userData?.guid, token]);

  useEffect(() => {
    if (token) {
      getSeedMotivations();
    }
  }, [token]);

  useEffect(() => {
    if (userData?.institute?.guid && token) {
      getDatasetList();
    }
  }, [userData?.institute?.guid, token]);

  function getProfileData() {
    dispatch(
      requestGetUserProfile({
        token,
      }),
    );
  }

  async function getOrderDetails(params) {
    dispatch(
      getorderByGuid({
        token,
        guid: params?.guid,
      }),
    );
  }

  useEffect(() => {
    if (token) {
      getUsersConfigData();
    }
  }, [token]);

  async function getUserRequesters() {
    dispatch(
      getRequesters({
        token,
        guid: userData?.guid,
      }),
    );
  }
  async function getSeedMotivations() {
    dispatch(
      getMotivations({
        token,
      }),
    );
  }

  async function getDatasetList() {
    dispatch(
      getSubscriptionDataset({
        token,
        guid: userData?.institute.guid,
        productCaseType: orderData?.product_case_type
      }),
    );
  }

  async function getUsersConfigData() {
    dispatch(
      getUsersConfig({
        token,
      }),
    );
  }

  function OnClickOfLoaderCloseButton() {
    clearTaskInterval();
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  }

  function handleOrderSuccessCallbackForChatrwd(task) {
    intervalId.current = setInterval(() => {
      getOrderTaskStatusForChatRwd(task);
    }, 3000);
  }

  function getOrderTaskStatusForChatRwd(task) {
    dispatch(
      fetchOrderTaskStatus({
        params: { task_ids: task },
        token: token || '',
        navigateToDashboard: navigateToDashboard,
        errorCallback: clearTaskInterval,
      }),
    );
  }

  function navigateToDashboard() {
    dispatchOrderPageAction({
      type: 'order/seIsOrderSubmitted',
      payload: false,
    });
    dispatch(clearOrderFormData());
    clearTaskInterval();
    history.push(urlConstantsEnum.ORDER_SUBMITTED);
  }

  function clearTaskInterval() {
    if (intervalId && intervalId.current) {
      clearInterval(intervalId.current);
    }
  }

  return (
    <>
      {isOrderLoading || isgettingOrderDetails ? (
        isOrderSubmitted ? (
          <ChatrwdOrderLoader handleClose={OnClickOfLoaderCloseButton} />
        ) : (
          <AuthLoader />
        )
      ) : (
        <div className="order-container pb-3 order-single-container">
          <Header />
          <div className="order-inner-container d-md-flex position-relative w-100">
            <Sidebar
              isSidebarHidden={isSidebarHidden}
              toggleSidebar={() => setIsSidebarHidden(true)}
            />
            <OrderSwitcher
              isSidebarHidden={isSidebarHidden}
              toggleSidebar={() => setIsSidebarHidden(false)}
              onOrderSuccessCallbackForChatrwd={handleOrderSuccessCallbackForChatrwd}
            />
          </div>
        </div>
      )}

      <ConfirmNavigationModal />
      <Unauthorized />
      <ConfirmStartOverModal />
    </>
  );
}

export default OrderpageLayout;
