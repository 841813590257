import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Auth from 'pages/Auth';
import Dashboard from 'pages/Dashboard';
import EmailVerification from 'pages/EmailVerification';
import StyleGuide from 'pages/StyleGuide';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoader from 'components/AuthLoader';
import OrderDashboard from 'pages/OrderDashboard';
import Profile from 'pages/Profile';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryModal from '../components/ErrorBoundaryModal';
import TopNav from 'components/TopNav';
import SearchResult from 'pages/SearchResult';
import UserUnverifiedTemplate from 'pages/UserUnverifiedTemplate';
import CheckIdleTimer from '../components/CheckIdleTimer';
import PaymentCancel from 'pages/PaymentCancel';
import PaymentSuccess from 'pages/PaymentSuccess';
import LogOutUtil from '../utils/LogOutUtil';

const Routes = () => {
  const { logOutUser } = LogOutUtil();
  const { isAuthenticated, isLoading } = useAuth0();
  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return <ErrorBoundaryModal error={error} resetErrorBoundary={resetErrorBoundary} />;
  };

  const logOutCallback = () => {
    logOutUser();
  };
  return !isLoading ? (
    <Router>
      <div className="app-container d-flex col-12">
        <div className="position-relative flex-1">
          <TopNav />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              logOutCallback();
            }}
          >
            {isAuthenticated ? (
              <>
                <CheckIdleTimer />
                <Switch>
                  <Route path="/success">
                    <PaymentSuccess />
                  </Route>
                  <Route path="/cancel">
                    <PaymentCancel />
                  </Route>
                  <Route path="/email-verification-pending">
                    <UserUnverifiedTemplate />
                  </Route>
                  <Route
                    path={[
                      '/dashboard/:tabID/:guid?',
                      '/onboarding/personal-info',
                      '/onboarding/terms-of-services',
                      '/onboarding/plan-and-pricing',
                    ]}
                  >
                    <Dashboard />
                  </Route>
                  <Route path="/order">
                    <OrderDashboard />
                  </Route>
                  <Route exact path="/styleguide">
                    <StyleGuide />
                  </Route>
                  <Route exact path="/orders/search/:tabID/:guid?">
                    <SearchResult />
                  </Route>
                  <Route exact path="/profile">
                    <Profile />
                  </Route>
                  <Route path="/email-verification/:token/:email">
                    <EmailVerification />
                  </Route>
                  <Route path="/">
                    <Redirect to="/dashboard/personal" />
                  </Route>
                </Switch>
              </>
            ) : (
              <Switch>
                <Route path="/email-verification/:token/:email">
                  <EmailVerification />
                </Route>
                <Route path="/order">
                  <OrderDashboard />
                </Route>
                <Route exact path="/">
                  <Auth />
                </Route>
                <Route path="/">
                  <Auth />
                </Route>
              </Switch>
            )}
          </ErrorBoundary>
        </div>
      </div>
    </Router>
  ) : (
    <AuthLoader fullScreen={true} />
  );
};

export default Routes;
