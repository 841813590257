import { user } from 'api/';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  REQUEST_GET_USER_PROFILE,
  UPDATE_PROFILE_INFO,
  UPDATE_PROFILE_INFO_START,
  UPDATE_PROFILE_INFO_COMPLETE,
  UPDATE_PROFILE_INFO_ERROR,
  EMAIL_VERIFICATION,
  EMAIL_VERIFICATION_START,
  EMAIL_VERIFICATION_COMPLETE,
  EMAIL_VERIFICATION_ERROR,
  SEND_EMAIL_VERIFICATION,
  SEND_EMAIL_VERIFICATION_START,
  SEND_EMAIL_VERIFICATION_COMPLETE,
  SEND_EMAIL_VERIFICATION_ERROR,
  PHONE_VERIFICATION,
  PHONE_VERIFICATION_START,
  PHONE_VERIFICATION_COMPLETE,
  PHONE_VERIFICATION_ERROR,
  SEND_PHONE_VERIFICATION,
  SEND_PHONE_VERIFICATION_START,
  SEND_PHONE_VERIFICATION_COMPLETE,
  SEND_PHONE_VERIFICATION_ERROR,
  setUserData,
  setLoading,
  resetData,
  SET_ERROR,
  REQUEST_GET_USER_PROFILE_START,
  REQUEST_GET_USER_PROFILE_COMPLETE,
  GET_PHYISICIAN_INSTITUTE_LIST,
  GET_PHYISICIAN_INSTITUTE_LIST_START,
  GET_PHYISICIAN_INSTITUTE_LIST_COMPLETE,
  GET_PHYISICIAN_INSTITUTE_LIST_ERROR,
  GET_PROFESSION_LIST,
  GET_PROFESSION_LIST_START,
  GET_PROFESSION_LIST_COMPLETE,
  GET_PROFESSION_LIST_ERROR,
  GET_SPECIALTY_DROPDOWN_LIST,
  GET_SPECIALTY_DROPDOWN_LIST_START,
  GET_SPECIALTY_DROPDOWN_LIST_COMPLETE,
  GET_SPECIALTY_DROPDOWN_LIST_ERROR,
  COMPLETE_PROFILE,
  COMPLETE_PROFILE_START,
  COMPLETE_PROFILE_COMPLETE,
  COMPLETE_PROFILE_ERROR,
  CREATE_NEW_USER,
  CREATE_NEW_USER_START,
  CREATE_NEW_USER_COMPLETE,
  CREATE_NEW_USER_ERROR,
  GET_REQUESTERS,
  GET_REQUESTERS_START,
  GET_REQUESTERS_COMPLETE,
  GET_REQUESTERS_ERROR,
  GET_USERS_CONFIG,
  GET_USERS_CONFIG_START,
  GET_USERS_CONFIG_COMPLETE,
  GET_USERS_CONFIG_ERROR,
} from './actions';
import orderFormErrorStr from '../../../constants/errorStrings';

export function* getUserProfile({ payload: { token, logOutCallback } }) {
  try {
    yield put(resetData());
    yield put({
      type: REQUEST_GET_USER_PROFILE_START,
    });
    const { response, error } = yield call(user.userProfile, {
      token,
    });

    if (!!response) {
      yield put({
        type: REQUEST_GET_USER_PROFILE_COMPLETE,
      });
      yield put(setUserData({ data: response.data }));
    } else {
      //call logout
      if (error.status === 401) {
        logOutCallback?.();
      } else {
        //Show error boundary if error is 500 or other
        yield put(
          onGetUserProfileError({
            error: error?.data.message
              ? error?.data.message
              : orderFormErrorStr.commonError,
          }),
        );
      }
    }
  } catch (error) {
    yield put(
      onGetUserProfileError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* updateUserProfile({
  payload: { token, params, userGuid, successCallback },
}) {
  try {
    yield put(resetData());
    yield put({
      type: UPDATE_PROFILE_INFO_START,
    });
    const { response, error } = yield call(user.updateUserProfileInfo, {
      token,
      params,
      userGuid,
    });

    if (!!response) {
      yield put({
        type: UPDATE_PROFILE_INFO_COMPLETE,
        payload: { data: response.data },
      });
      successCallback?.();
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onUpdateUserProfileError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onUpdateUserProfileError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* emailVerification({ payload: { params } }) {
  try {
    yield put(resetData());
    yield put({
      type: EMAIL_VERIFICATION_START,
    });
    const { response, error } = yield call(user.emailVerification, {
      params,
    });

    if (!!response) {
      yield put({
        type: EMAIL_VERIFICATION_COMPLETE,
        payload: { data: response.data.message },
      });
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onEmailVerificationError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onEmailVerificationError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getRequesters({ payload: { token, guid } }) {
  try {
    yield put(resetData());
    // yield put(setLoading({ data: true }));
    yield put({
      type: GET_REQUESTERS_START,
    });
    const { response, error } = yield call(user.getRequesters, {
      token,
      guid,
    });

    if (!!response) {
      yield put({
        type: GET_REQUESTERS_COMPLETE,
        payload: { requesters: response.data },
      });
      yield put(setLoading({ data: false }));
    } else {
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onGetRequestersError({
          requesters: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onGetRequestersError({
        requesters: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* sendEmailVerification({ payload: { token, params } }) {
  try {
    yield put(resetData());
    yield put({
      type: SEND_EMAIL_VERIFICATION_START,
    });
    const { response, error } = yield call(user.sendEmailVerification, {
      token,
      params,
    });

    yield getUserProfile({ payload: { token } });
    if (!!response) {
      yield put({
        type: SEND_EMAIL_VERIFICATION_COMPLETE,
        payload: { data: response.message },
      });
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onSendEmailVerificationError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onSendEmailVerificationError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* phoneVerification({
  payload: { token, params, onVerifyPhoneOtp, onVerifyPhoneOtpError },
}) {
  try {
    yield put(resetData());
    yield put({
      type: PHONE_VERIFICATION_START,
    });
    const { response, error } = yield call(user.phoneVerification, {
      token,
      params,
    });

    if (!!response) {
      yield put({
        type: PHONE_VERIFICATION_COMPLETE,
        payload: { data: response.data.message },
      });
      onVerifyPhoneOtp?.();
    } else {
      onVerifyPhoneOtpError?.();
      //Show error boundary if error is 500 or other
      yield put(
        onPhoneVerificationError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    onVerifyPhoneOtpError?.();
    yield put(
      onPhoneVerificationError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* sendPhoneVerification({
  payload: { token, params, onSendPhoneVerificationCallback },
}) {
  try {
    yield put(resetData());
    yield put({
      type: SEND_PHONE_VERIFICATION_START,
    });
    const { response, error } = yield call(user.sendPhoneVerification, {
      token,
      params,
    });

    onSendPhoneVerificationCallback?.();
    if (!!response) {
      yield put({
        type: SEND_PHONE_VERIFICATION_COMPLETE,
        payload: { data: response.message },
      });
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onSendPhoneVerificationError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onSendPhoneVerificationError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getPhysicianInstituteTermList({ payload: { token, params } }) {
  try {
    yield put({
      type: GET_PHYISICIAN_INSTITUTE_LIST_START,
    });
    const { response, error } = yield call(user.getPhysicianInstituteTermList, {
      token,
      params,
    });

    if (!!response) {
      yield put({
        type: GET_PHYISICIAN_INSTITUTE_LIST_COMPLETE,
        payload: { data: response.data },
      });
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onGetPhysicianInstituteTermListError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onGetPhysicianInstituteTermListError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getProfessionList({ payload: { token } }) {
  try {
    yield put({
      type: GET_PROFESSION_LIST_START,
    });
    const { response, error } = yield call(user.getProfessionList, {
      token,
    });

    if (!!response) {
      yield put({
        type: GET_PROFESSION_LIST_COMPLETE,
        payload: { data: response.data },
      });
    } else {
      yield put(
        onGetProfessionListError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onGetProfessionListError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getSpecialtyDropDownList({ payload: { token } }) {
  try {
    yield put({
      type: GET_SPECIALTY_DROPDOWN_LIST_START,
    });
    const { response, error } = yield call(user.getSpecialtyDropDownList, {
      token,
    });

    if (!!response) {
      yield put({
        type: GET_SPECIALTY_DROPDOWN_LIST_COMPLETE,
        payload: { data: response.data },
      });
    } else {
      yield put(
        onGetSpecialtyDropDownListError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onGetSpecialtyDropDownListError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* requestCompleteProfile({ payload: { token, userGuid } }) {
  try {
    yield put({
      type: COMPLETE_PROFILE_START,
    });
    const { response, error } = yield call(user.requestCompleteProfile, {
      token,
      userGuid,
    });

    if (!!response) {
      yield put({
        type: COMPLETE_PROFILE_COMPLETE,
        payload: { data: response.data },
      });
    } else {
      yield put(
        onRequestCompleteProfileError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onRequestCompleteProfileError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* createNewUserProfile({
  payload: { params, csrfToken, successCallback },
}) {
  try {
    yield put({
      type: CREATE_NEW_USER_START,
    });
    const { response, error } = yield call(user.createNewUser, {
      params,
      csrfToken,
    });

    if (!!response) {
      yield put({
        type: CREATE_NEW_USER_COMPLETE,
        payload: { data: response.data },
      });
      successCallback();
    } else {
      yield put(
        onCreateNewUserProfile({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onCreateNewUserProfile({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getUsersConfig({ payload: { token } }) {
  try {
    yield put({
      type: GET_USERS_CONFIG_START,
    });
    const { response, error } = yield call(user.getUsersConfig, {
      token,
    });

    if (!!response) {
      yield put({
        type: GET_USERS_CONFIG_COMPLETE,
        payload: { data: response.data },
      });
    } else {
      yield put(
        onGetUsersConfigError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onGetUsersConfigError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

const onGetUsersConfigError = (payload = {}) => ({
  type: GET_USERS_CONFIG_ERROR,
  payload,
});

const onCreateNewUserProfile = (payload = {}) => ({
  type: CREATE_NEW_USER_ERROR,
  payload,
});

const onRequestCompleteProfileError = (payload = {}) => ({
  type: COMPLETE_PROFILE_ERROR,
  payload,
});

const onGetSpecialtyDropDownListError = (payload = {}) => ({
  type: GET_SPECIALTY_DROPDOWN_LIST_ERROR,
  payload,
});

const onGetProfessionListError = (payload = {}) => ({
  type: GET_PROFESSION_LIST_ERROR,
  payload,
});

const onGetPhysicianInstituteTermListError = (payload = {}) => ({
  type: GET_PHYISICIAN_INSTITUTE_LIST_ERROR,
  payload,
});

const onSendPhoneVerificationError = (payload = {}) => ({
  type: SEND_PHONE_VERIFICATION_ERROR,
  payload,
});

const onPhoneVerificationError = (payload = {}) => ({
  type: PHONE_VERIFICATION_ERROR,
  payload,
});

const onSendEmailVerificationError = (payload = {}) => ({
  type: SEND_EMAIL_VERIFICATION_ERROR,
  payload,
});

const onEmailVerificationError = (payload = {}) => ({
  type: EMAIL_VERIFICATION_ERROR,
  payload,
});

const onGetUserProfileError = (payload = {}) => ({
  type: SET_ERROR,
  payload,
});

const onUpdateUserProfileError = (payload = {}) => ({
  type: UPDATE_PROFILE_INFO_ERROR,
  payload,
});

export const onGetRequestersError = (payload = {}) => ({
  type: GET_REQUESTERS_ERROR,
  payload,
});

function* watchGetUsersConfig() {
  yield takeLatest(GET_USERS_CONFIG, getUsersConfig);
}

function* watchRequestUserProfile() {
  yield takeLatest(REQUEST_GET_USER_PROFILE, getUserProfile);
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE_INFO, updateUserProfile);
}

function* watchEmailVerification() {
  yield takeLatest(EMAIL_VERIFICATION, emailVerification);
}

function* watchSendEmailVerification() {
  yield takeLatest(SEND_EMAIL_VERIFICATION, sendEmailVerification);
}

function* watchPhoneVerification() {
  yield takeLatest(PHONE_VERIFICATION, phoneVerification);
}

function* watchSendPhoneVerification() {
  yield takeLatest(SEND_PHONE_VERIFICATION, sendPhoneVerification);
}
function* watchGetPhysicianInstituteList() {
  yield takeLatest(GET_PHYISICIAN_INSTITUTE_LIST, getPhysicianInstituteTermList);
}

function* watchGetProfessionList() {
  yield takeLatest(GET_PROFESSION_LIST, getProfessionList);
}

function* watchGetSpecialtyDropDownList() {
  yield takeLatest(GET_SPECIALTY_DROPDOWN_LIST, getSpecialtyDropDownList);
}

function* watchRequestCompleteProfile() {
  yield takeLatest(COMPLETE_PROFILE, requestCompleteProfile);
}

function* watchCreateNewUserProfile() {
  yield takeLatest(CREATE_NEW_USER, createNewUserProfile);
}

function* watchRequestRequesters() {
  yield takeLatest(GET_REQUESTERS, getRequesters);
}

export const sagas = [
  watchRequestUserProfile,
  watchUpdateProfile,
  watchEmailVerification,
  watchSendEmailVerification,
  watchPhoneVerification,
  watchSendPhoneVerification,
  watchGetPhysicianInstituteList,
  watchGetProfessionList,
  watchGetSpecialtyDropDownList,
  watchRequestCompleteProfile,
  watchCreateNewUserProfile,
  watchRequestRequesters,
  watchGetUsersConfig,
];
