import React, { useState } from 'react';
import styles from './dropdown.module.scss';
import DropdownContainer from './DropdownContainer';
import Multiselect from './Multiselect';
import Select from './Select';
import Grouped from './Grouped';

function DropdownMenu({
  placeholder,
  type = 'standard',
  options = [],
  selectedOptions = {},
  onCheckboxCheck = () => {},
  disabled = false,
  onClickonOption = () => {},
  isClearable = false,
  onClearSelectedOption = () => {},
  groupKey,
  onGroupCheckboxCheck = () => {},
  isNoValidate = false,
  noScroll = false,
  showTooltipInfo = false,
  height,
  optionsPadding,
  onDropdownControlClick = () => {},
  actions = {
    buttonAction: '',
    actionValue: '',
    actionLabel: '',
  },
  readOnly = true,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleInputControlClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      onDropdownControlClick();
    }
  };

  const handleCloseDropDown = () => {
    setIsOpen(false);
    // onDropdownClosed();
  };

  const onMultiSelectCheckboxCheck = (e, option) => {
    handleCloseDropDown();
    onCheckboxCheck(e, option);
  };

  const handleGroupCheckboxCheck = (e, option, group) => {
    handleCloseDropDown();
    onGroupCheckboxCheck(e, option, group);
  };

  const handleOptionClick = (option) => {
    handleCloseDropDown();
    onClickonOption(option);
  };

  return (
    <DropdownContainer
      isOpen={isOpen}
      onCloseDropDown={handleCloseDropDown}
      onInputControlClick={handleInputControlClick}
      placeholder={placeholder}
      dropdownType={type}
      disabled={disabled}
      isClearable={isClearable}
      onClearSelectedOption={onClearSelectedOption}
      isNoValidate={isNoValidate}
      noScroll={noScroll}
      height={height}
      selectedOption={selectedOptions}
      actions={actions}
      readOnly={readOnly}
    >
      <ul
        className={`${styles.listwrapper} ${
          type === 'standard' ? styles.listwrapperSpace : ''
        }`}
      >
        {type === 'multiselect' ? (
          <Multiselect
            selectedOptions={selectedOptions}
            options={options}
            onCheckboxCheck={onMultiSelectCheckboxCheck}
          />
        ) : type === 'group' ? (
          <Grouped
            options={options}
            groupKey={groupKey}
            onGroupCheckboxCheck={handleGroupCheckboxCheck}
            selectedOptions={selectedOptions}
            showTooltipInfo={showTooltipInfo}
          />
        ) : (
          <Select
            options={options}
            onOptionClick={handleOptionClick}
            selectedOptions={selectedOptions}
            optionsPadding={optionsPadding}
          />
        )}
      </ul>
    </DropdownContainer>
  );
}

export default DropdownMenu;
