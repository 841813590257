import React, { useEffect, useState } from 'react';
import { ButtonLink } from 'components/form-input/Button';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Strings } from 'constants/Strings';
import CustomModal from 'components/CustomModal';
import { ButtonCustom } from 'components/form-input/Button';
import {
  uploadOrderDocument,
  removeUploadedFile,
} from 'redux/modules/orderDetails/actions';
import { getRawToken } from 'utils/validations';
import AuthLoader from 'components/AuthLoader';
import { AiFillDelete } from 'react-icons/ai';
import { MdError } from 'react-icons/md';

function Documentupload() {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const {
    isUploadingDocument,
    uploadedFilesList,
    getDocumentUploadError,
    orderFormData,
    singleOrderData,
  } = useSelector(({ orderDetails }) => orderDetails);
  const [isDocumentUploadClicked, setIsDocumentUploadClicked] = useState(false);
  const [documentError, setDocumentError] = useState('');
  const [isMaxDocumentUploaded, setIsMaxDocumentUploaded] = useState(false);
  const [filePath, setFilePath] = useState('');
  const [isShowPhiModal, setIsShowPhiModal] = useState(false);
  const [documentFile, setDocumentFile] = useState();
  const { documents } = singleOrderData || {};

  useEffect(() => {
    if (documents && documents.length) {
      setIsDocumentUploadClicked(true);
    }
  }, [documents]);

  const handleDocumentUpload = () => {
    mixpanel.track(
      'Document upload option ' + !isDocumentUploadClicked
        ? 'opened'
        : 'closed' + ' event',
    );
    if (!isDocumentUploadClicked) {
      setIsDocumentUploadClicked(true);
    } else {
      setIsDocumentUploadClicked(false);
    }
  };

  const handleMaxDocUploadErr = (e) => {
    if (uploadedFilesList && uploadedFilesList.length == 5) {
      e.preventDefault();
      setIsMaxDocumentUploaded(true);
    }
  };

  const handleDocumentInputUpload = (e) => {
    if (e.target.files[0]) {
      var supportedExtensions = /(\.docx|\.pdf|\.csv|\.xlsx|\.pptx)$/i; //supported extensions
      var maxfilesize = 1024 * 1024 * 25; //Allowing 25MB
      var filesize = e.target.files[0].size;
      if (filesize > maxfilesize) {
        setDocumentError(Strings.documentSizeError);
      } else if (!supportedExtensions.exec(e.target.files[0].name)) {
        setDocumentError(Strings.documentExtensionError);
      } else {
        setDocumentError('');
        setIsShowPhiModal(true);
        setDocumentFile(e.target.files[0]);
      }
    } else {
      setIsShowPhiModal(false);
    }
  };

  const OnClickOfPhiModalClose = () => {
    setIsShowPhiModal(false);
  };

  const OnClickOfPhiModalConfirm = () => {
    uploadDocument(true);
    setIsShowPhiModal(false);
  };

  const uploadDocument = async (phiDisclaimerStatus = false) => {
    var data = new FormData();
    data.append('file', documentFile);
    const token = await getIdTokenClaims();
    if (token) {
      dispatch(
        uploadOrderDocument({
          token: getRawToken(token),
          params: data,
          tokenExpiredCallback: redirectToLogin,
          fileDetails: {
            file: documentFile,
            phiDisclaimerStatus,
          },
        }),
      );
    } else {
      redirectToLogin();
    }
  };

  const redirectToLogin = () => {
    history.push(urlConstantsEnum.LOGIN);
  };

  const handleRemoveDocument = (index) => {
    if (isMaxDocumentUploaded) {
      setIsMaxDocumentUploaded(false);
    }
    dispatch(removeUploadedFile(index));
  };

  return (
    <>
      <div className="advaced-settings-div">
        <div
          className="d-flex cursor-pointer"
          onClick={() => handleDocumentUpload()}
          id={'document-upload-option'}
        >
          <div className="advanced-setting-title">Document Upload</div>
          <div
            className={
              !isDocumentUploadClicked
                ? 'button-position right-arrow'
                : 'button-position right-arrow rotate-button'
            }
          >
            <ButtonLink
              buttonAction="Document Upload section clicked"
              id={'document-upload-icon'}
            >
              <HiOutlineChevronRight />
            </ButtonLink>
          </div>
        </div>
        {isDocumentUploadClicked ? (
          <>
            {!isUploadingDocument ? (
              <>
                <div
                  className={`advanced-setting-body mt-2 ${
                    !isAuthenticated ? 'disbaled-document-upload' : ''
                  }`}
                >
                  {uploadedFilesList && uploadedFilesList.length > 0 ? (
                    <div className="chip-container my-3">
                      {uploadedFilesList &&
                        uploadedFilesList.length > 0 &&
                        uploadedFilesList.map((doc, index) => (
                          <div className="chip" key={`${doc.key_path + index}`}>
                            <span className="chip-label">{doc.file_name}</span>
                            <AiFillDelete
                              onClick={() => handleRemoveDocument(index)}
                              className="chip-icon"
                            />
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="no-file mt-4">No file selected</div>
                  )}
                  {documentError !== '' || getDocumentUploadError !== undefined ? (
                    <div className="document-format-text my-3 document-error d-flex flex-items-center">
                      <MdError />
                      <div className="ml-2">
                        {documentError !== '' ? documentError : getDocumentUploadError}
                      </div>
                    </div>
                  ) : isMaxDocumentUploaded ? (
                    <div className="document-format-text my-3 document-error d-flex flex-items-center">
                      <MdError size={50} />
                      <div className="ml-2">
                        You have reached the upload limit of 5 documents. Please contact{' '}
                        <a
                          className="text-primary"
                          href="mailto:consult@atroposhealth.com"
                        >
                          consult@atroposhealth.com
                        </a>{' '}
                        if you need to provide further documentation.
                      </div>
                    </div>
                  ) : (
                    <div className="document-format-text my-3">
                      {Strings.documentExtensionNote}
                    </div>
                  )}

                  <>
                    {isAuthenticated ? (
                      <input
                        disabled={!isAuthenticated}
                        type="file"
                        name="document"
                        id="document-upload"
                        className="inputfile d-none"
                        accept=".docx,.pdf,.csv,.xlsx,.pptx"
                        value={filePath}
                        onChange={(e) => {
                          handleDocumentInputUpload(e);
                        }}
                        onClick={(e) => {
                          handleMaxDocUploadErr(e);
                          setFilePath('');
                        }}
                      />
                    ) : (
                      ''
                    )}
                    <label htmlFor="document-upload" className="inputfile-label">
                      Choose
                    </label>
                  </>
                </div>
                {!isAuthenticated ? (
                  <div className="document-disabled-text">
                    To upload a document, please login or create an account{' '}
                    <ButtonCustom
                      onClick={() => OnClickOfLogin()}
                      cssClass="mt-2 login-btn"
                      buttonAction="Sign in button clicked"
                      id={'btn-login-button'}
                    >
                      here
                    </ButtonCustom>
                    .
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              <div className="loader-btn-view">
                <AuthLoader fullScreen={false} />
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
      <CustomModal
        isOpen={isShowPhiModal}
        handleClose={OnClickOfPhiModalClose}
        cssClass="white-overlay"
        modalname="PHI modal"
        overlayCssClass={'phi-modal-overlay'}
      >
        <div className="email-modal p-5 delete-modal phi-modal">
          <div className="profile-sub-header mt-4 mb-2">{Strings.phiModalText1}</div>
          <div className="d-md-flex flex-justify-end">
            <ButtonCustom
              cssClass="plain-btn mt-5 px-4"
              onClick={() => OnClickOfPhiModalClose()}
              buttonAction={'Cancel PHI button clicked'}
              actionLabel={'Cancel document contains PHI'}
              id={'btn-cancel-phi-button'}
            >
              Cancel
            </ButtonCustom>
            <ButtonCustom
              cssClass="btn-primary mt-2 mt-md-5 px-4 ml-md-3"
              onClick={() => OnClickOfPhiModalConfirm()}
              buttonAction={'PHI confirm button clicked'}
              actionLabel={'Confirm document does not have PHI'}
              id={'btn-confirm-phi-button'}
            >
              Confirm
            </ButtonCustom>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default Documentupload;
